/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/default': {
    width: 44,
    height: 44,
    viewBox: '0 0 44 44',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11 3.066a4.267 4.267 0 00-4.267 4.267v29.333A4.267 4.267 0 0011 40.933h22a4.267 4.267 0 004.267-4.267V14.674v-.007a.595.595 0 00-.176-.425l-11-11a.6.6 0 00-.424-.176H11zM35.22 14.067l-8.952-8.952v8.952h8.952zm-10.152-9.8H11a3.067 3.067 0 00-3.067 3.066v29.333A3.067 3.067 0 0011 39.733h22a3.067 3.067 0 003.067-3.067V15.267h-10.4a.6.6 0 01-.6-.6v-10.4zm-11 19.567a.6.6 0 01.6-.6h14.666a.6.6 0 110 1.2H14.667a.6.6 0 01-.6-.6zm.6 6.733a.6.6 0 000 1.2h14.666a.6.6 0 100-1.2H14.667zm-.6-14.067a.6.6 0 01.6-.6h3.666a.6.6 0 110 1.2h-3.666a.6.6 0 01-.6-.6z" _fill="#0077C8"/>'
  }
})
