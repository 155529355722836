<template>
    <section class="mp-metals__container container u-flex u-bottom-margin--3xl">
        <div class="mp-metals">
            <div class="mp-metals__wrapper">
                <z-caption
                    :href="device === 'mobile' ? false : '/business/sustainable-metals/'"
                    :tag="device === 'mobile' ? 'h2' : 'a'"
                    size="3xl"
                    decor-left
                    decor-right
                    class="mp-metals__title u-left-padding--4xs"
                    theme="white">
                    <span data-scroll-heading v-html="metals.title"></span>
                </z-caption>
                <p
                    data-scroll-translate
                    class="mp-metals__text u-bottom-margin--xl"
                    v-html="metals.text"></p>
                <div class="mp-metals__list" data-scroll-translate>
                    <div
                        class="mp-metals__list-item"
                        v-for="(item, index) in metals.list"
                        :key="index">
                        <dash class="mp-metals__dash">
                            <template v-slot:value>{{ item.metal }}</template>
                            <template v-slot:text>{{ item.name }}</template>
                        </dash>
                    </div>
                </div>
            </div>
        </div>
        <div class="mp-metals__role">
            <z-caption
                tag="h2"
                size="2xl"
                class="mp-metals__role-title u-bottom-margin--l-important">
                <span data-scroll-heading v-html="metalsRole.title"></span>
            </z-caption>
            <simple-slider data-scroll-translate>
                <slide
                    v-for="(roleItem, index) in metalsRole.content"
                    :key="index">
                    <div class="u-bottom-margin--xs">
                        <div class="mp-metals__role-slide u-bottom-padding--s">
                            <img
                                :src="roleItem.img"
                                alt=""
                                class="mp-metals__role-img" />
                            <p
                                class="mp-metals__role-subtitle"
                                v-html="roleItem.title"></p>
                        </div>
                        <p
                            class="mp-metals__role-text"
                            v-html="roleItem.text"></p>
                    </div>
                </slide>
            </simple-slider>
        </div>
    </section>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'
export default {
    name: 'section-metals',
    mixins: [mixinDevice],
    data () {
        return {
            metals: {
                title: localize({
                    ru: 'Наши металлы',
                    en: 'Our metals'
                }),
                text: localize({
                    ru: '&laquo;Норникель&raquo;&nbsp;&mdash; глобальный лидер по&nbsp;производству металлов, необходимых для экологически чистого транспорта и&nbsp;развития низкоуглеродной экономики.',
                    en: 'Nornickel is&nbsp;the global leader in&nbsp;the production of&nbsp;metals that make transport clean and help the world develop low-carbon economy.'
                }),
                list: [
                    {
                        metal: 'Ni',
                        name: localize({
                            ru: 'Никель',
                            en: 'Nickel'
                        })
                    },
                    {
                        metal: 'Cu',
                        name: localize({
                            ru: 'Медь',
                            en: 'Copper'
                        })
                    },
                    {
                        metal: 'Pd',
                        name: localize({
                            ru: 'Палладий',
                            en: 'Palladium'
                        })
                    },
                    {
                        metal: 'Pt',
                        name: localize({
                            ru: 'Платина',
                            en: 'Platinum'
                        })
                    }
                ]
            },
            metalsRole: {
                title: localize({
                    ru: 'Pоль металлов<br>в&nbsp;экономике будущего',
                    en: 'The role of&nbsp;metals in&nbsp;the economy of&nbsp;the future'
                }),
                content: [
                    {
                        title: localize({
                            ru: 'Аккумуляторы',
                            en: 'Batteries'
                        }),
                        text: localize({
                            ru: 'Металл используют при производстве аккумуляторов — никель-кадмиевых, железо-никелевых, никель-цинковых, никель-водородных.',
                            en: 'Nickel is&nbsp;used in&nbsp;the production of&nbsp;Ni-Cd, Fe-Ni, Ni-Zn, and Ni-H2&nbsp;batteries.'
                        }),
                        img: localize({
                            ru: '/images/main-page/a.png',
                            en: '/images/main-page/b.png'
                        })
                    },
                    {
                        title: localize({
                            ru: 'Ортодонтия',
                            en: 'Orthodontics'
                        }),
                        text: localize({
                            ru: 'Никель широко применяется в&nbsp;ортодонтии: в&nbsp;никель-хромовых сплавах и&nbsp;нитиноловой проволоке, в&nbsp;некоторых припоях белого золота и&nbsp;различных типов стали, используемых в&nbsp;ортодонтической практике.',
                            en: 'Nickel has a&nbsp;lot of&nbsp;applications in&nbsp;orthodontics: it&nbsp;is&nbsp;used in&nbsp;Ni-Cr alloys and nitinol wires, some types of&nbsp;white gold solders and a&nbsp;variety of&nbsp;steel types employed in&nbsp;orthodontic care.'
                        }),
                        img: '/images/main-page/o.png'
                    },
                    {
                        title: localize({
                            ru: 'Никелирование',
                            en: 'Nickel Electroplating'
                        }),
                        text: localize({
                            ru: 'Никелирование железных сплавов позволяет защитить их&nbsp;от&nbsp;коррозии, например, при производстве резервуаров для хранения и&nbsp;перевозки химически агрессивных веществ.',
                            en: 'Through nickel electroplating, ferroalloys develop resistance to&nbsp;corrosion, which, among other things, is&nbsp;used in&nbsp;the production of&nbsp;tanks for storage and transportation of&nbsp;corrosive substances.'
                        }),
                        img: localize({
                            ru: '/images/main-page/h.png',
                            en: '/images/main-page/n.png'
                        })
                    }
                ]
            }
        }
    }
}
</script>

<style lang="scss">
.mp-metals {
    border-radius: 0px $token-spacers-2-xl 0px 0px;
    background-color: $token-colors-gray-20;
    background-image: url('/images/main-page/our-metals.jpg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    @include padding-level(top, XL);
    width: 68%;

    @include breakpoint(v-tablet) {
        width: 100%;
    }

    .z-caption__text {
        @include breakpoint(mobile) {
            @include margin-level(left, 2XS, false, true);
        }
    }

    &__container {
        @include breakpoint(v-tablet) {
            flex-direction: column;
        }
    }

    &__wrapper {
        padding: 0 109px 88px $token-spacers-2-xl;

        @include breakpoint(mobile) {
            padding: 40px 20px;
        }
    }

    &__title {
        margin-bottom: 68px;
    }

    &__text {
        font-weight: 600;
        font-size: 26px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: $token-colors-white;

        @include breakpoint(laptop) {
            @include typo-level(2XL);
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &-item:not(:last-child) {
            margin-right: $token-spacers-m;
            margin-bottom: $token-spacers-s;
        }

        @include breakpoint(tablet) {
            & &-item {
                margin-right: 0;
                margin-bottom: $token-spacers-s;
                width: 50%;
            }
        }

        @include breakpoint(mobile) {
            & &-item {
                margin-bottom: $token-spacers-s;
                width: 100%;
            }
        }
    }

    &__dash {
        .dash__text {
            color: $token-colors-white !important;
        }
    }

    &__role {
        width: 23%;
        @include margin-level(left, 3XL);

        @include breakpoint(v-tablet) {
            @include margin-level(top, L);
            margin-left: 0;
            width: 100%;
        }

        .simple-slider__controls {
            margin-top: 0;
        }
    }

    &__role-title {
        margin-bottom: 28px;
        font-weight: 600;
        font-size: 25px;
        line-height: 120%;
        color: $token-colors-gray-60;
    }

    &__role-img {
        margin-right: $token-spacers-3-xs;
    }

    &__role-subtitle {
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        color: $token-colors-black;
        align-self: center;
    }

    &__role-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: $token-colors-gray-60;
    }

    &__role-slide {
        display: flex;
        flex-wrap: wrap;
    }
}
</style>
