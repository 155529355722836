/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-2': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<g clip-path="url(#clip0_9226_31773)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.255.965a.6.6 0 10-1.2 0v1.283H10.992V.965a.6.6 0 10-1.2 0v1.283H5.684a.6.6 0 00-.6.6v17.924a2.477 2.477 0 002.482 2.483h17.925a2.484 2.484 0 002.482-2.483V2.849a.6.6 0 00-.6-.601h-4.12V.965zM9.792 4.743V3.448H6.284v17.324c0 .713.57 1.283 1.282 1.283h17.925c.702 0 1.282-.572 1.282-1.283V8.167H8.086a.6.6 0 010-1.2h18.688V3.448h-3.52v1.295a.6.6 0 11-1.2 0V3.448H10.993v1.295a.6.6 0 11-1.2 0zM.964 6.025a.6.6 0 00-.6.6v18.866a2.493 2.493 0 002.483 2.482h17.924a2.493 2.493 0 002.483-2.482v-1.036a.6.6 0 10-1.2 0v1.036c0 .7-.583 1.282-1.283 1.282H2.848c-.7 0-1.283-.582-1.283-1.282V11.946h2.32a.6.6 0 100-1.2h-2.32v-3.52h2.32a.6.6 0 000-1.2H.964zm7.544 5.921h1.882v-1.2H8.51v1.2zm6.602 0h-1.883v-1.2h1.883v1.2zm2.824 0h1.896v-1.2h-1.896v1.2zm1.896 3.766h-1.896v-1.2h1.896v1.2zm-1.896 3.777h1.896v-1.2h-1.896v1.2zm6.602-7.543h-1.882v-1.2h1.882v1.2zm-1.882 3.766h1.882v-1.2h-1.882v1.2zm1.882 3.777h-1.882v-1.2h1.882v1.2zM8.51 15.712h1.882v-1.2H8.51v1.2zm6.602 0h-1.883v-1.2h1.883v1.2zm-6.602 3.777h1.882v-1.2H8.51v1.2zm6.602 0h-1.883v-1.2h1.883v1.2z" _fill="#858DA6"/></g><defs><clipPath id="clip0_9226_31773"><path pid="1" _fill="#fff" d="M0 0h28v28H0z"/></clipPath></defs>'
  }
})
