<template>
    <div class="clean-norilsk">
        <banner @scroll-to="scrollTo" />

        <timeline>
            <div class="swiper-slide">
                <div class="cn-timeline__item">
                    <div class="cn-timeline__item">
                        <tl-year year="2021" />
                    </div>
                </div>
                <div class="cn-timeline__item">
                    <tl-key year="2021" title="Планы" text="Анонсированы первые планы реализации программы по 2030 год включительно">
                        <div class="row">
                            <div class="col-default-6 col-v-tablet-12">
                                <z-key-indicator
                                    size="s"
                                    value="40"
                                    value-text="млрд рублей"
                                    name="Общий бюджет всей программы"
                                ></z-key-indicator>
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                                <z-key-indicator
                                    size="s"
                                    value="750"
                                    value-text="сотрудников"
                                    name="План найма"
                                ></z-key-indicator>
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                                <z-key-indicator
                                    size="s"
                                    value="3"
                                    value-text="млрд рублей"
                                    name="Предварительно выделено"
                                ></z-key-indicator>
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                                <z-key-indicator
                                    size="s"
                                    value="120"
                                    value-text="единиц спецтехники"
                                    name="План закупок"
                                ></z-key-indicator>
                            </div>
                        </div>
                    </tl-key>
                </div>
                <div class="cn-timeline__item">
                    <tl-logo month="Апрель" href="https://www.ttelegraf.ru/news/v-norilske-startovala-programma-po-blagoustrojstvu-promyshlennyh-territorij/" image="/images/clean-norilsk/tl-logo-1.svg" title="Старт работ" />
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom image="/images/clean-norilsk/tl-bottom-image.jpg" month="Апрель" second-month="Май" title="Ведутся работы на первом крупном объекте программы – в районе Надеждинского металлургического завода" />
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom month="Май" second-month="Июнь" title="Проведены работы на объектах НТЭК и Медвежий ручей" />
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom month="Июль" href="https://www.ttelegraf.ru/news/na-promyshlennyh-territoriyah-norilska-prodolzhaetsya-masshtabnaya-uborka/" title="За 4 месяца со старта программы">
                        <z-key-indicator
                            size="s"
                            value="308"
                            value-text="тысяч кв.м. территории"
                            name="Очищено"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="47,1"
                            value-text="тыс. тонн отходов"
                            name="Вывезено"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="3,1"
                            value-text="тыс. тонн металлолома"
                            name="Собрано на переработку"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="99"
                            value-text="тыс. куб.м."
                            name="Объем демонтированных объектов"
                        ></z-key-indicator>
                    </tl-img-bottom>
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom image="/images/clean-norilsk/tl-bottom-image.jpg" href="https://www.ttelegraf.ru/news/na-promyshlennyh-territoriyah-norilska-prodolzhaetsya-masshtabnaya-uborka/" month="Октябрь" title="Проведена уборка на месте бывшего поселка Купец">
                        <z-key-indicator
                            size="m"
                            value="29"
                            value-text="тыс. куб.м."
                            name="площадь расчищенной территории"
                        ></z-key-indicator>
                    </tl-img-bottom>
                </div>
                <div class="cn-timeline__item">
                    <tl-key year="2021" title="Итоги 10 месяцев">
                        <div class="row">
                            <div class="col-default-6 col-v-tablet-12">
                                <div class="row">
                                    <div class="col-default-12">
                                        <z-key-indicator
                                            size="s"
                                            value="87"
                                            value-text="сооружений"
                                            name="Демонтировано"
                                        ></z-key-indicator>
                                    </div>
                                    <div class="col-default-12">
                                        <z-key-indicator
                                            size="s"
                                            value="1"
                                            value-text="млн кв. м. территории"
                                            name="Очищено"
                                        ></z-key-indicator>
                                    </div>
                                    <div class="col-default-12">
                                        <z-key-indicator
                                            size="s"
                                            value="280"
                                            value-text="тыс. тонн отходов"
                                            name="Вывезено"
                                        ></z-key-indicator>
                                    </div>
                                    <div class="col-default-12">
                                        <z-key-indicator
                                            size="s"
                                            value="40"
                                            value-text="тыс. тонн металлолома"
                                            name="Собрано на переработку"
                                        ></z-key-indicator>
                                    </div>
                                </div>
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                                <div class="row">
                                    <div class="col-default-12">
                                        <z-key-indicator
                                            size="xs"
                                            value="270"
                                            value-text="рабочих"
                                            name="Заполярный филиал привлекал к работе"
                                        ></z-key-indicator>
                                    </div>
                                    <div class="col-default-12">
                                        <z-key-indicator
                                            size="xs"
                                            value="60"
                                            value-text="вахтовиков"
                                        ></z-key-indicator>
                                    </div>
                                    <div class="col-default-12">
                                        <z-key-indicator
                                            size="xs"
                                            value="27"
                                            value-text="подрядных организаций"
                                        ></z-key-indicator>
                                    </div>
                                    <div class="col-default-12">
                                        <z-key-indicator
                                            size="s"
                                            value="3,4"
                                            value-text="млрд рублей"
                                            name="Объем инвестиций в спецоборудование"
                                        ></z-key-indicator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tl-key>
                </div>
                <div class="cn-timeline__item">
                    <tl-key year="2021" title="Итоги">
                        <z-key-indicator
                            size="s"
                            value="123"
                            value-text="сооружения"
                            name="Демонтировано"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="40"
                            value-text="тыс. тонн металлолома"
                            name="Собрано"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="330"
                            value-text="тыс. тонн мусора"
                            text-left=">"
                            name="вывезено"
                        ></z-key-indicator>
                    </tl-key>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="cn-timeline__item">
                    <div class="cn-timeline__item">
                        <tl-year year="2022" />
                    </div>
                </div>
                <div class="cn-timeline__item">
                    <tl-key year="2022" title="Планы" month="Январь">
                        <z-key-indicator
                            size="s"
                            value="100"
                            value-text="объектов"
                            name="Снос порядка"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="2"
                            value-text="млн кв.м. территории"
                            name="Саночистка до"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="5"
                            value-text="млрд рублей"
                            name="бюджет на комплексную очистку территории"
                        ></z-key-indicator>
                    </tl-key>
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom image="/images/clean-norilsk/tl-bottom-image.jpg" video="https://www.youtube.com/watch?v=xWfbdKi9LJA&list=PLEpxq-WzEvFlankyT8V6jazH0mfFFGuMa&index=25" title="Итоги работы на НМЗ в 2021 году – расчищено 350 000 кв.м. территории"></tl-img-bottom>
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom title="За зимний период продолжались работы на следующих территориях и предприятиях:">
                        <z-list>
                            <z-list-item>Надеждинский металлургический завод</z-list-item>
                            <z-list-item>в районе Кайеркан</z-list-item>
                            <z-list-item>стройбаза «Надежда»</z-list-item>
                            <z-list-item>НТЭК</z-list-item>
                            <z-list-item>Норильский обеспечивающий комплекс</z-list-item>
                            <z-list-item>рудники «Маяк», «Таймырский», «Октябрьский»</z-list-item>
                            <z-list-item>предприятия «Норильсктрансгаз» и «Норильскгазпром»</z-list-item>
                            <z-list-item>Общество «Медвежий ручей»</z-list-item>
                        </z-list>
                    </tl-img-bottom>
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom :full="true" image="/images/clean-norilsk/tl-image-bottom.png" video="https://youtu.be/UoWnMnFXB8Q" title="Проведена уборка на территории рудника «Маяк»"></tl-img-bottom>
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom image="/images/clean-norilsk/tl-bottom-image.jpg" video="https://youtu.be/-QHXN-8GCyM">
                        <z-list>
                            <z-list-item>Ведется уборка на Пеляткинском газоконденсатном месторождении</z-list-item>
                            <z-list-item>За два года действия программы вывезено около 6,8 тыс. тонн металлолома</z-list-item>
                        </z-list>
                    </tl-img-bottom>
                </div>
                <div class="cn-timeline__item">
                    <tl-logo day="27" month="июня" href="https://24rus.ru/news/economy/195629.html" image="/images/clean-norilsk/timeline-logo.svg" title="«Чистый Норильск» назван лучшей экологической программой по результатам подведения итогов Национальной премии в области экологических технологий «Экотех-Лидер». Премия вручается за достижения в области разработки, внедрения и продвижения передовых экологических технологий как важнейшего элемента повестки ESG" />
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom month="октбрь" title="Предварительные итоги работ в летний период:">
                        <z-key-indicator
                            size="s"
                            value="12"
                            value-text="аварийных зданий и сооружений"
                            name="Рекордное количество подрядных организаций"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="90"
                            name="Максимальное количество техники на пике"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="100"
                            value-text="сотрудников внешних организаций"
                            text-left="до"
                            name="Ежедневно к работам привлекалось"
                        ></z-key-indicator>
                    </tl-img-bottom>
                </div>
                <div class="cn-timeline__item">
                    <tl-logo day="13" month="октября" href="https://www.ttelegraf.ru/news/ot-staroj-prachechnoj-v-norilske-vysvobodyat-pochti-tri-tysyachi-kvadratnyh-metrov-zemli/" image="/images/clean-norilsk/timeline-logo-3.svg" title="Победа в номинации «ESG. Вывод из эксплуатации и ликвидация» на премии «Russian Demolition Awards»" />
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom image="/images/clean-norilsk/tl-bottom-image.jpg" month="Октябрь" title="Создано новое подразделение по переработке лома черных металлов мощностью переработки до 15-20 тонн металлолома в час"></tl-img-bottom>
                </div>
                <div class="cn-timeline__item">
                    <tl-key title="Предварительные итоги" month="Декабрь">
                        <z-key-indicator
                            size="s"
                            value="126"
                            value-text="аварийных зданий и сооружений"
                            name="Демонтировано"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="470"
                            value-text="тыс. тонн мусора"
                            text-left=">"
                            name="Вывезено"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="25"
                            value-text="тыс. тонн металлолома"
                            text-left=">"
                            name="Собрано"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="1,5"
                            value-text="млн кв. м."
                            name="Общая площадь уборки"
                        ></z-key-indicator>
                    </tl-key>
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom month="Октябрь" title="Проведен технический этап рекультивации нарушенных земель в районе Надеждинского завода на площади почти 78,8 га">
                        <z-key-indicator
                            size="m"
                            value="78,8"
                            value-text="га"
                            name="площадь земель"
                        ></z-key-indicator>
                    </tl-img-bottom>
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom :full="true" image="/images/clean-norilsk/tl-image-bottom.png" video="https://youtu.be/nuAMzYdT4PM" title="Интервью Андрея Радунцева по итогам года"></tl-img-bottom>
                </div>
                <div class="cn-timeline__item">
                    <tl-key year="2022" title="Итоги">
                        <z-key-indicator
                            size="s"
                            value="262"
                            value-text="аварийных и неэкспуатируемых объекта"
                            name="Демонтировано"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="814"
                            value-text="тыс. тонн мусора"
                            name="Собрано и вывезено"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="71"
                            value-text="тыс. тонн металлолома"
                            name="Отправлено на переработку"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="s"
                            value="2,6"
                            value-text="млн. кв. м"
                            name="Общая площадь территории очистки"
                        ></z-key-indicator>
                    </tl-key>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="cn-timeline__item">
                    <tl-year year="2023" />
                </div>
                <div class="cn-timeline__item">
                    <tl-key year="2023" title="Планы" text="Озвучен план на 2023 год – увеличить показатели программы в два раза по сравнению с предыдущим годом"></tl-key>
                </div>
                <div class="cn-timeline__item">
                    <tl-img-bottom month="Июль" title="Подведены итоги работы за&nbsp;6&nbsp;месяцев 2023&nbsp;г:">
                        <z-key-indicator
                            size="m"
                            value="31"
                            value-text="здание и&nbsp;сооружение"
                            name="Демонтировано"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="m"
                            value="102"
                            value-text="тыс. тонн мусора"
                            name="Собрано и&nbsp;вывезено"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="m"
                            value="226"
                            value-text="тыс. кв. м."
                            name="Очищено"
                        ></z-key-indicator>
                        <z-key-indicator
                            size="m"
                            value="85"
                            value-text="тыс. кв. м."
                            name="Рекультивировано"
                        ></z-key-indicator>
                    </tl-img-bottom>
                </div>
                <div class="cn-timeline__item">
                    <tl-more href="/clean-norilsk/process/program-progress/" image="/images/clean-norilsk/tl-more.png" />
                </div>
            </div>
        </timeline>

        <cn-steps ref="steps" />

        <objects-switcher/>

        <div class="container">
            <quotes-slider/>
        </div>
    </div>
</template>

<script>
import CnSteps from './components/CnSteps.vue'
import Banner from './components/Banner.vue'
import QuotesSlider from './components/QuotesSlider.vue'
import Timeline from '../Timeline/Timeline.vue'
import ObjectsSwitcher from '@/views/CleanNorilsk/views/Main/components/ObjectsSwitcher/ObjectsSwitcher.vue'

export default {
    name: 'clean-norilsk',
    components: {
        ObjectsSwitcher,
        Banner,
        QuotesSlider,
        CnSteps,
        Timeline
    },
    methods: {
        scrollTo () {
            this.$refs.steps.$el.scrollIntoView({block: 'center', behavior: 'smooth'})
        }
    }
}
</script>

<style lang="scss">
.clean-norilsk {
    .clean-banner {
        margin-bottom: $token-spacers-s;
        &:after {
            content: '';
            height: 62px;
            width: 100%;
            background-image: url('/images/clean-norilsk/banner-bottom-line.png');
            background-position: center left;
            background-repeat: repeat-x;
            display: block;
            position: absolute;
            bottom: 0;
            background-size: cover;

            @include breakpoint(v-tablet) {
                height: 24px;
            }

            @include breakpoint(mobile) {
                height: 20px;
            }
        }
    }

    .cn-timeline {
        padding: 0 0 $token-spacers-m;

        @include breakpoint(v-tablet) {
            padding: 0 0 $token-spacers-2-xl;
            margin-bottom: -24px;
        }

        @include breakpoint(mobile) {
            padding: 0 0 $token-spacers-2-xl;
            margin-bottom: 20px;
        }
    }

    .pseudo-slider {
        position: relative;
        padding-bottom: 207px;
        transform-style: preserve-3d;

        @include breakpoint(laptop) {
            padding-bottom: 198px;
        }

        @include breakpoint(tablet) {
            padding-bottom: 160px;
        }

        @include breakpoint(v-tablet) {
            padding-bottom: 104px;
        }

        @include breakpoint(mobile) {
            padding-bottom: 84px;
        }

        .container {
            transform: translateZ(2px);
        }

        &:after {
            content: '';
            height: 300px;
            width: 100%;
            background-image: url('/images/clean-norilsk/pseudo-slider-bottom-line.png');
            background-position: top left;
            background-repeat: repeat-x;
            display: block;
            position: absolute;
            bottom: 0;
            background-size: cover;
            transform: translateZ(0);

            @include breakpoint(v-tablet) {
                height: 120px;
            }

            @include breakpoint(mobile) {
                height: 80px;
            }
        }

        &:before {
            content: '';
            height: 55px;
            width: 100%;
            background-image: url('/images/clean-norilsk/banner-bottom-line.png');
            background-position: center left;
            background-repeat: repeat-x;
            display: block;
            position: absolute;
            bottom: -1px;
            background-size: contain;
            transform: translateZ(1px);

            @include breakpoint(laptop) {
                height: 46px;
                background-size: cover;
            }

            @include breakpoint(tablet) {
                height: 32px;
            }

            @include breakpoint(v-tablet) {
                bottom: -2px;
                height: 24px;
            }

            @include breakpoint(mobile) {
                height: 20px;
            }
        }
    }

    .cn-steps {
        position: relative;

        &:before {
            content: '';
            height: 47px;
            width: 100%;
            background-image: url('/images/clean-norilsk/timeline-bottom-line.png');
            background-position: center left;
            background-repeat: repeat-x;
            display: block;
            position: absolute;
            top: -2px;
            background-size: cover;
            z-index: 1;

            @media (min-width: 1921px) {
                background-size: contain;
            }

            @include breakpoint(v-tablet) {
                height: 24px;
            }

            @include breakpoint(mobile) {
                height: 20px;
            }
        }
    }

    .default-slider {
        margin-bottom: -5%;;
        margin-top: $token-spacers-4-xl;

        @include breakpoint(v-tablet) {
            margin-top: 104px;
        }

        @include breakpoint(mobile) {
            margin-top: $token-spacers-xl;
        }
    }
}
</style>
