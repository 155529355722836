import { localize } from '@/utils/i18n'

export const text = {
    name: localize({
        ru: 'ФИО',
        en: 'Full name'
    }),
    email: localize({
        ru: 'E-mail',
        en: 'E-mail'
    }),
    message: localize({
        ru: 'Вопрос',
        en: 'Question'
    }),
    button: localize({
        ru: 'Отправить',
        en: 'Send'
    }),
    notificationRequiredTextError: localize({
        ru: 'Заполните все обязательные поля',
        en: 'Fill in all required fields'
    }),
    notificationError: localize({
        ru: 'Ошибка отправки',
        en: 'Send error'
    }),
    notificationValidateError: localize({
        ru: 'Неправильный формат полей',
        en: 'Wrong field format'
    }),
    notificationSuccess: localize({
        ru: 'Отправка успешна',
        en: 'Sending successful'
    }),
    notificationCaptchaError: localize({
        ru: 'Неверный код капчи. Попробуйте снова.',
        en: 'Invalid captcha code. Try again.'
    }),
    notificationCaptchaEmpty: localize({
        ru: 'Введите код с картинки',
        en: 'Enter the code from the image*'
    }),
    policy: localize({
        ru: 'Заполняя поля данной формы Вы добровольно передаете свои персональные данные (имя, фамилия, адрес электронной почты) ПАО «ГМК «Норильский никель».',
        en: 'In accordance with the Federal Law of 27.07.2006 No. 152-FL by my free consent, at my own will, and in my interest I agree to the processing of my personal data, which is mentioned in feedback form on website www.nornickel.com, by PJSC “MMC “Norilsk Nickel”'
    })
}
