/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'minning': {
    width: 74,
    height: 49,
    viewBox: '0 0 74 49',
    data: '<path pid="0" d="M65.528 19.09v12.398h-2.254A10.102 10.102 0 0153.13 21.344v-2.255h12.398zm0 0H42.31c-1.803 0-3.156 1.352-3.156 3.155v10.821h4.734c5.185 0 9.243 4.283 9.243 9.243h12.398m0-23.22v23.22m0 0V37.8h3.156v4.509h-3.156zM7.818 15.933v10.821m10.37-10.82V30.36m10.37-14.428v18.26m18.71-22.317L30.588.604 13.905 11.876h33.364zm25.474 0H.605V25.4l28.855 12.4h7.213V21.794c0-2.931 2.48-5.41 5.41-5.41h30.885c-.226.225-.226-4.51-.226-4.51zM13.23 41.858c0 1.578 1.352 2.93 2.93 2.93s2.93-1.352 2.93-2.93-1.352-2.93-2.93-2.93-2.93 1.352-2.93 2.93zm28.404 0c0 1.578 1.352 2.93 2.93 2.93s2.931-1.352 2.931-2.93-1.352-2.93-2.93-2.93-2.931 1.352-2.931 2.93zm-3.607 0a6.542 6.542 0 006.537 6.537 6.542 6.542 0 006.538-6.537 6.542 6.542 0 00-6.538-6.538 6.542 6.542 0 00-6.537 6.538zm-4.96-1.127h-4.959c-1.127 0-1.803.901-1.803 1.803v.902c0 1.127.901 1.803 1.803 1.803h4.96c1.127 0 1.803-.901 1.803-1.803v-.902c.226-.902-.676-1.803-1.803-1.803zM9.623 41.858a6.542 6.542 0 006.537 6.537 6.542 6.542 0 006.538-6.537 6.542 6.542 0 00-6.538-6.538 6.542 6.542 0 00-6.537 6.538z" _stroke="#1A1B25" stroke-width="1.2" stroke-miterlimit="10" stroke-linejoin="round"/>'
  }
})
