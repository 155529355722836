/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outlook': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 2c5.533 0 10 4.467 10 10s-4.467 10-10 10S2 17.533 2 12 6.467 2 12 2zM5.667 7.067L13.133 5.6v12.8l-7.466-1.533v-9.8zm7.933 3.8V8.333h4.2c.2 0 .467.267.467.534L14.533 11.4h-.066l-.867-.533zm-2.933-.534c-.334-.4-.734-.6-1.2-.6-.534 0-.934.267-1.2.6-.334.467-.467 1-.467 1.667s.133 1.2.467 1.6c.333.4.733.6 1.2.6.466 0 .866-.2 1.2-.6.333-.467.466-1 .466-1.667 0-.666-.133-1.2-.466-1.6zm4 2c-.134.067 3.666-2.466 3.666-2.466v4.6c0 .533-.333.733-.666.733H13.6v-3.4l.8.533c.133.067.267 0 .267 0zM8.8 11c.2-.267.4-.4.667-.4.266 0 .466.133.733.4.133.267.2.6.2 1.067 0 .4-.133.733-.267 1-.2.2-.4.333-.666.333a.713.713 0 01-.6-.4c-.2-.267-.267-.6-.267-1s.067-.733.2-1z" _fill="#0077C8"/>'
  }
})
