/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cn/reclamation': {
    width: 58,
    height: 58,
    viewBox: '0 0 58 58',
    data: '<g clip-path="url(#clip0_7569_66517)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.925 21.05L6.5 20.525C10.1 11.225 19.1 5 29 5h1.8l-5.025 5.025-1.05-1.05 2.4-2.4c-8.55.75-16.125 6.375-19.2 14.475zM53 30.8l-5.025-5.026 1.05-1.05 2.4 2.4c-.75-8.55-6.375-16.124-14.475-19.2l.525-1.424C46.775 10.1 53 19.1 53 29v1.8zM27.2 53H29c9.9 0 18.9-6.225 22.425-15.45L50 37.025c-3.075 8.025-10.575 13.65-19.125 14.4l2.4-2.4-1.05-1.05L27.2 53zm-6.75-1.575C11.225 47.9 5 38.9 5 29v-1.8l5.025 5.025-1.05 1.05-2.4-2.4c.75 8.55 6.375 16.05 14.4 19.125l-.525 1.425zm8.025-4.575l.525.45.525-.525.038-.038h.001C30.244 46.072 38 38.497 38 29c0-9.026-7.006-16.316-8.284-17.647a5.143 5.143 0 01-.191-.203L29 10.7l-.525.525-.039.038C27.756 11.928 20 19.504 20 29c0 9.026 7.005 16.316 8.284 17.647.103.107.169.175.191.203zm-3.167-29.343a32.617 32.617 0 012.942-3.89v6.833l-2.942-2.943zm-.795 1.306l3.737 3.737v5.4l-5.475-5.475-.008.008a25.666 25.666 0 011.746-3.67zM28.25 35.45v-5.4l-6.007-6.007c-.433 1.48-.71 3.044-.74 4.66l6.747 6.747zm0 2.1v6.834c-2.057-2.33-5.924-7.44-6.636-13.47l6.636 6.636zm1.5 0l6.636-6.636c-.712 6.03-4.58 11.14-6.636 13.47V37.55zm6.747-8.847L29.75 35.45v-5.4l6.007-6.007c.433 1.48.71 3.044.74 4.66zm-1.272-6.228l.008.008a25.673 25.673 0 00-1.746-3.67L29.75 22.55v5.4l5.475-5.475zm-2.532-4.967a32.643 32.643 0 00-2.943-3.892v6.834l2.943-2.942z" _fill="#ADD1EA"/></g><defs><clipPath id="clip0_7569_66517"><path pid="1" _fill="#fff" transform="translate(5 5)" d="M0 0h48v48H0z"/></clipPath></defs>'
  }
})
