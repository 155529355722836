/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-txt': {
    width: 33,
    height: 22,
    viewBox: '0 0 33 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.869 16.714h1.494V6.04h3.816V4.708H.07V6.04h3.798v10.674zm15.593 0h1.8L16.6 10.54l4.392-5.832h-1.818l-3.546 4.806-3.564-4.806h-1.818l4.41 5.85-4.68 6.156h1.818l3.834-5.112 3.834 5.112zm6.415 0h1.494V6.04h3.816V4.708h-9.108V6.04h3.798v10.674z" _fill="#0077C8"/>'
  }
})
