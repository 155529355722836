var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mp-reports container"},[_c('div',{staticClass:"u-collapse--top col-default-8 col-desktop-12 u-all-padding--no"},[_c('div',{staticClass:"mp-reports__results"},[_c('z-caption',{staticClass:"main-page__title u-top-margin--l-important u-bottom-margin--m-important",attrs:{"href":_vm.device === 'mobile'
                        ? false
                        : '/investors/reports-and-results/current-results/',"size":"3xl","decor-left":"","decor-right":""}},[_c('span',{attrs:{"data-scroll-heading":""},domProps:{"innerHTML":_vm._s(_vm.text.reportSection.title)}})]),_vm._v(" "),_c('div',{staticClass:"mp-reports__reports",attrs:{"data-scroll-translate":""}},[(_vm.reports['annual-report'])?_c('report-block',{staticClass:"mp-reports__reports-item",attrs:{"data":{
                        image: _vm.reports['annual-report'].arImg.link,
                        name: ((_vm.text.finrez.annualReports) + " " + (_vm.reports['annual-report'].name) + " " + (_vm.text.finrez.year)),
                        fileLink: _vm.reports['annual-report'].arFile.link,
                        onlinelink: _vm.reports['annual-report'].arUrl.value,
                        taggedFileLink: _vm.reports['annual-report'].tvArFile.link
                    }}}):_vm._e(),_vm._v(" "),(_vm.reports['sustability-report'])?_c('report-block',{staticClass:"mp-reports__reports-item",attrs:{"data":{
                        image: _vm.reports['sustability-report'].csrImg.link,
                        name: ((_vm.text.finrez.sustainabilityReports) + " " + (_vm.reports['sustability-report'].name) + " " + (_vm.text.finrez.year)),
                        fileLink: _vm.reports['sustability-report'].csrFile.link,
                        onlinelink: _vm.reports['sustability-report'].csrUrl.value
                    }}}):_vm._e(),_vm._v(" "),(_vm.reports['press-release'])?_c('report-block',{staticClass:"mp-reports__reports-item reports__reports-item--manufacture-results",attrs:{"isTargetBlank":false,"data":{
                        image: _vm.imageUrl.report,
                        learnMoreLink:
                            _vm.reports['press-release'].pr4PressRelease.detailPageUrl ||
                            _vm.reports['press-release'].pr3PressRelease.detailPageUrl ||
                            _vm.reports['press-release'].pr2PressRelease.detailPageUrl ||
                            _vm.reports['press-release'].pr1PressRelease.detailPageUrl,
                        name:
                            _vm.reports['press-release'].pr4PressRelease.name ||
                            _vm.reports['press-release'].pr3PressRelease.name ||
                            _vm.reports['press-release'].pr2PressRelease.name ||
                            _vm.reports['press-release'].pr1PressRelease.name
                    }}}):_vm._e()],1)],1)]),_vm._v(" "),_c('div',{staticClass:"mp-reports__interview"},[_c('z-caption',{staticClass:"main-page__title u-bottom-margin--m-important",attrs:{"tag":"h2","decor-left":"","decor-right":"","size":"m","weight":"700","uppercase":""}},[_c('span',{attrs:{"data-scroll-heading":""},domProps:{"innerHTML":_vm._s(_vm.text.interviewSection.title)}})]),_vm._v(" "),_c('div',{staticClass:"mp-reports__interview-wrapper",attrs:{"data-scroll-translate":""}},_vm._l((_vm.media),function(media,index){return _c('div',{key:index,staticClass:"mp-reports__interview-container"},[_c('z-icon',{staticClass:"mp-reports__interview-icon",attrs:{"name":"monitor-play","width":"48","height":"48"}}),_vm._v(" "),_c('div',{staticClass:"mp-reports__interview-item"},[_c('date-block',{attrs:{"date":media.activeFrom,"theme":"inline"}},[(_vm.lang === 'ru')?[_vm._v(" г.")]:_vm._e()],2),_vm._v(" "),_c('a',{staticClass:"mp-reports__interview-link",attrs:{"href":media.detailPageUrl}},[_vm._v("\n                        "+_vm._s(media.name)+"\n                    ")])],1)],1)}),0),_vm._v(" "),_c('z-button',{staticClass:"u-align-self--start",attrs:{"href":"/news-and-media/media-library/","tag":"a","kind":"text"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('z-icon',{attrs:{"name":"arrow-long","dir":"right","width":"16","height":"16"}})]},proxy:true}])},[_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.text.interviewSection.button)}})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }