/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-docx': {
    width: 49,
    height: 22,
    viewBox: '0 0 49 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 16.715h4.104c3.726 0 6.156-2.574 6.156-5.994 0-3.402-2.43-6.012-6.156-6.012H0v12.006zm8.712-5.994c0 2.574-1.692 4.662-4.608 4.662h-2.61V6.04h2.61c2.97 0 4.608 2.088 4.608 4.68zm3.405 0c0 3.546 2.394 6.21 5.959 6.21 3.546 0 5.957-2.664 5.957-6.21s-2.412-6.21-5.957-6.21c-3.565 0-5.959 2.664-5.959 6.21zm10.368 0c0 2.79-1.727 4.878-4.41 4.878-2.7 0-4.41-2.088-4.41-4.878 0-2.808 1.71-4.878 4.41-4.878 2.683 0 4.41 2.07 4.41 4.878zm3.396 0c0 3.69 2.718 6.21 6.174 6.21 2.124 0 3.564-1.008 4.572-2.376l-1.278-.702a4.004 4.004 0 01-3.294 1.746c-2.628 0-4.626-2.034-4.626-4.878 0-2.862 1.998-4.878 4.626-4.878 1.368 0 2.61.738 3.294 1.746l1.26-.702c-.954-1.35-2.43-2.376-4.554-2.376-3.456 0-6.174 2.52-6.174 6.21zm20.948 5.994h1.8l-4.662-6.174 4.392-5.832h-1.818l-3.546 4.806-3.564-4.806h-1.818l4.41 5.85-4.68 6.156h1.818l3.834-5.112 3.834 5.112z" _fill="#0077C8"/>'
  }
})
