/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16 31C7.716 31 1 24.284 1 16 1 7.716 7.716 1 16 1c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zM0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z" _fill="#0077C8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19.458 9.182a1.775 1.775 0 100 3.55 1.775 1.775 0 000-3.55zm-2.215 3.761a2.975 2.975 0 10-.605-1.036l-4.465 2.606a2.975 2.975 0 100 3.972l4.467 2.602a2.975 2.975 0 10.606-1.035l-4.467-2.604a2.972 2.972 0 000-1.899l4.464-2.606zm-5.771 2.628a.57.57 0 00.04.07 1.768 1.768 0 01-.042 1.789 1.774 1.774 0 01-3.287-.93 1.775 1.775 0 013.289-.93zm6.211 6.47c0-.296.073-.575.2-.82a.604.604 0 00.09-.151 1.775 1.775 0 11-.289.971z" _fill="#0077C8"/>'
  }
})
