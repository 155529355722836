/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'danger_mini': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<g clip-path="url(#clip0_9226_31808)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.31 1.834c-7.435 0-13.478 6.043-13.478 13.477 0 7.435 6.043 13.478 13.478 13.478s13.477-6.043 13.477-13.478c0-7.434-6.042-13.477-13.477-13.477zM.895 15.311C.895 7.36 7.357.897 15.31.897c7.952 0 14.415 6.463 14.415 14.415 0 7.953-6.463 14.416-14.415 14.416-7.953 0-14.415-6.463-14.415-14.415zM9.335 5.05a.469.469 0 01.64.172l3.707 6.415a.469.469 0 11-.812.47L9.402 6.103a10.964 10.964 0 00-5.086 8.831h6.938a.469.469 0 010 .938H3.839a.469.469 0 01-.469-.468A11.904 11.904 0 019.334 5.05zm11.287.184c.13-.224.415-.3.64-.172a11.909 11.909 0 015.987 10.343c0 .259-.21.469-.468.469h-7.415a.469.469 0 110-.938h6.936a10.968 10.968 0 00-5.107-8.82l-3.469 6.002a.469.469 0 01-.811-.469l3.707-6.415zM9.165 25.099a.469.469 0 00.171.64 11.905 11.905 0 0011.95-.012c.224-.13.3-.416.171-.64l-3.707-6.421a.469.469 0 00-.812.468l3.468 6.008a10.965 10.965 0 01-10.19.011l3.463-6.004a.469.469 0 10-.812-.468l-3.702 6.418zm3.924-8.934a2.379 2.379 0 114.442-1.705 2.379 2.379 0 01-4.442 1.705zm1.032-3.949a3.316 3.316 0 102.377 6.192 3.316 3.316 0 00-2.377-6.192z" _fill="#0077C8"/></g><defs><clipPath id="clip0_9226_31808"><path pid="1" _fill="#fff" d="M0 0h30v30H0z"/></clipPath></defs>'
  }
})
