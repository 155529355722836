<template>
    <form @submit.prevent="sendFormData" class="corporate-hotline-form">
        <div
            class="corporate-hotline-form__container u-bottom-margin--xl col-default-5 col-tablet-12"
        >
            <z-select
                v-model="formData.category"
                :data="companyOptions"
                ref="category"
                required
                :placeholder="text.company"
                :value="defaultValue"
                :deleteSelected="deleteSelected"
                class="corporate-hotline-form__input"
            ></z-select>

            <z-input
                v-model="formData.fio"
                type="text"
                name="fio"
                :title="text.name"
                class="corporate-hotline-form__input"
            ></z-input>

            <z-input
                v-model="formData.email"
                type="email"
                required
                ref="email"
                name="email"
                :title="`${text.email}${isAppealed.length ? '*' : ''}`"
                class="corporate-hotline-form__input"
            ></z-input>

            <z-input
                v-model="formData.phone"
                ref="tel"
                type="tel"
                name="phone"
                :title="text.tel"
                class="corporate-hotline-form__input"
            ></z-input>

            <p class="corporate-hotline-form__text">{{ text.captcha }}</p>
            <div class="corporate-hotline-form__captcha-container col-default-12">
                <img
                    class="corporate-hotline-form__captcha-img"
                    v-if="isUpdatedCapcha"
                    :src="'/api/captcha/?' + capchaSalt"
                    width="100"
                    height="50"
                    alt=""
                />
                <div v-else class="corporate-hotline-form__captcha-img"></div>

                <z-button
                    class="corporate-hotline-form__captcha-button"
                    type="button"
                    rounded
                    kind="secondary"
                    @click="updateCapcha"
                >
                    <z-icon name="refresh" width="24" height="24"></z-icon>
                </z-button>

                <z-input
                    v-model="captcha"
                    type="text"
                    required
                    name="captcha"
                    ref="captcha"
                    class="col-default-6 corporate-hotline-form__captcha-input"
                ></z-input>
            </div>
        </div>

        <div class="corporate-hotline-form__container col-default-5 col-tablet-12">
            <z-textarea
                v-model="formData.msg"
                required
                name="msg"
                ref="msg"
                :title="text.question"
                class="corporate-hotline-form__input corporate-hotline-form__input--textarea"
            ></z-textarea>

            <z-checkbox
                v-model="isAppealed"
                class="corporate-hotline-form__checkbox"
                name="results-appeal"
                ref="results-appeal"
                :data="[{ value: '1' }]"
            >
                <template #text>
                    <z-caption tag="p" weight="400" size="xs">{{ text.resultsAppeal }} </z-caption>
                </template>
            </z-checkbox>

            <z-checkbox
                v-model="isChecked"
                class="corporate-hotline-form__checkbox"
                required
                name="policy"
                ref="policy"
                :data="[{ value: '1' }]"
            >
                <template #text>
                    <z-caption tag="p" weight="400" size="xs">{{ text.policy }} </z-caption>
                </template>
            </z-checkbox>

            <z-button>
                {{ text.button }}
            </z-button>
        </div>
    </form>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { corporateHotlineForm } from '@/api/corporate-hotline-form.js'
import { sendCaptcha } from '@/api/captcha.js'
import { companyOptions, text } from './constans'

export default {
    name: 'corporate-hotline-form',
    data () {
        return {
            isChecked: [],
            isAppealed: [],
            isLoading: false,
            defaultValue: '',
            formData: {
                form: 'corporate-hotline',
                email_to: 'skd@nornik.ru',
                category: null,
                fio: '',
                email: '',
                phone: '',
                msg: '',
                phrase: this.captcha,
                corporateHotlineCheckbox: 'on',
                ajax_call: 'Y',
                results_appeal: false
            },
            text,
            captcha: '',
            captchaImg: null,
            capchaSalt: Date.now(),
            isUpdatedCapcha: true,
            companyOptions,
            deleteSelected: false
        }
    },
    computed: {
        requiredFields () {
            return this.isAppealed.length ? ['category', 'policy', 'msg', 'captcha', 'email'] : ['category', 'policy', 'msg', 'captcha']
        }
    },
    methods: {
        validateInputFormat () {
            if (this.formData.phone || this.formData.email) {
                const requiredFields = ['email', 'tel']
                let isValid = true

                requiredFields.forEach((item) => {
                    const field = this.$refs[item]
                    if (field) {
                        field.validate()
                        if (field.error) isValid = false
                    }
                })
                return isValid
            }

            return true
        },

        validateRequiredInputs () {
            const requiredFields = this.requiredFields
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },

        changeTextarea (data) {
            this.formData[data.name] = data.value
        },

        onChange () {
            this.isChecked = !this.isChecked
        },

        updateCapcha () {
            this.isUpdatedCapcha = false
            this.captcha = ''
            this.capchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCapcha = true
            }, 500)
        },

        async sendFormData () {
            if (this.isLoading) {
                return
            }

            const isValidRequiredInputs = this.validateRequiredInputs()

            if (isValidRequiredInputs) {
                const isValidInputFormat = this.validateInputFormat()

                if (!isValidInputFormat) {
                    showNotyfications(this.text.notificationValidateError, { type: 'error' })
                    return
                }

                try {
                    this.isLoading = true

                    if (this.captcha === '') {
                        showNotyfications(this.text.notificationCaptchaEmpty, { type: 'error' })
                        return
                    }

                    this.formData.results_appeal = this.isAppealed.length > 0

                    const data = new FormData()

                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }
                    const captcha = {
                        phrase: this.captcha
                    }

                    const response = await sendCaptcha(captcha)

                    if (response.status === false) {
                        showNotyfications(this.text.notificationCaptchaError, { type: 'error' })
                    } else {
                        const formResponse = await corporateHotlineForm(data)

                        if (formResponse.success === true) {
                            showNotyfications(this.text.notificationSuccess, { type: 'success' })
                            this.defaultValue = ''
                            this.formData.category = null
                            this.formData.fio = ''
                            this.formData.email = ''
                            this.formData.phone = ''
                            this.captcha = ''
                            this.formData.phrase = ''
                            this.formData.msg = ''
                            this.deleteSelected = true
                            this.isChecked = []
                        } else {
                            showNotyfications(response.public.message, { type: 'error' })
                        }
                    }
                    this.updateCapcha()
                } catch (error) {
                    console.log(error)
                    showNotyfications(this.text.notificationError, { type: 'error' })
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications(this.text.notificationRequiredTextError, { type: 'error' })
            }
        }
    }
}
</script>

<style lang="scss">
.corporate-hotline-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 330px) {
        margin-right: 16px;
    }

    &__container {
        padding-right: 0;
        padding-left: 0;
    }

    &__input {
        margin-bottom: $token-spacers-s;

        &--textarea {
            margin-bottom: 60px;
        }
    }

    &__text {
        font-size: 16px;
    }

    &__required {
        text-align: right;
        font-size: 20px;
        margin-bottom: 0;
    }

    &__captcha-container {
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        padding-left: 0;
    }

    &__captcha-img {
        margin: 0 16px 16px 0;
        width: 100px;
        height: 50px;
    }

    &__captcha-button {
        margin-right: 8px;
    }

    &__captcha-input {
        margin-bottom: 16px;

        @media screen and (max-width: 393px) {
            padding: 0 !important;
        }
    }

    &__checkbox {
        margin-bottom: $token-spacers-s;

        .z-checkbox__text {
            font-size: 13px !important;
        }
    }
}
</style>
