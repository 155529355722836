/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mini_history': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.47 2.2C6.797 2.2 2.2 6.798 2.2 12.47c0 2.271.738 4.371 1.987 6.072 1.61-1.74 3.522-3 5.618-3.586a4.676 4.676 0 115.328 0c2.096.586 4.008 1.847 5.619 3.586 1.249-1.7 1.987-3.8 1.987-6.073 0-5.671-4.598-10.269-10.27-10.269zm0 20.539a10.241 10.241 0 01-7.51-3.265c2.116-2.337 4.726-3.685 7.51-3.685 2.782 0 5.392 1.349 7.508 3.686a10.241 10.241 0 01-7.509 3.264zM1 12.469C1 6.135 6.135 1 12.47 1c6.334 0 11.469 5.135 11.469 11.47 0 6.334-5.135 11.469-11.47 11.469C6.135 23.939 1 18.804 1 12.469zm7.993-1.357a3.476 3.476 0 116.952 0 3.476 3.476 0 01-6.952 0z" _fill="#0077C8"/>'
  }
})
