/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'img': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.951 4.2H5A1.8 1.8 0 003.2 6v8.906l2.39-1.854a1.356 1.356 0 011.534-.127l3.237 1.88 4.71-4.104c.194-.169.433-.272.681-.307.248-.034.504-.001.735.1l.006.002 4.258 1.913V6a1.8 1.8 0 00-1.8-1.8zm1.8 9.525l-4.746-2.132a.169.169 0 00-.086-.01.122.122 0 00-.06.023h-.001l-5.032 4.386-.324.282-.372-.215-3.608-2.095a.158.158 0 00-.09-.018.127.127 0 00-.07.025l-.012.011-.014.01L3.2 16.424v2.197a1.8 1.8 0 001.8 1.8h13.951a1.8 1.8 0 001.8-1.8v-4.896zM5 3a3 3 0 00-3 3v12.621a3 3 0 003 3h13.951a3 3 0 003-3V6a3 3 0 00-3-3H5zm1.792 4.791a.546.546 0 10-.772-.772.546.546 0 00.772.772zm.848.849a1.746 1.746 0 10-2.468-2.47A1.746 1.746 0 007.64 8.64z" _fill="#0077C8"/>'
  }
})
