/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.024 7.977a.6.6 0 00-.848 0l-6 6a.6.6 0 10.848.848L11.6 9.25l5.576 5.575a.6.6 0 10.848-.848l-6-6z" _fill="#0077C8"/>'
  }
})
