/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'products-burger': {
    width: 23,
    height: 23,
    viewBox: '0 0 23 23',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.5 1C17.299 1 22 5.701 22 11.5S17.299 22 11.5 22 1 17.299 1 11.5 5.701 1 11.5 1zm0-1C17.851 0 23 5.149 23 11.5S17.851 23 11.5 23 0 17.851 0 11.5 5.149 0 11.5 0zm-4 8.414a.6.6 0 000 1.2h9a.6.6 0 100-1.2h-9zm0 2.987a.6.6 0 000 1.2h9a.6.6 0 100-1.2h-9zm-.6 3.583a.6.6 0 01.6-.6h9a.6.6 0 110 1.2h-9a.6.6 0 01-.6-.6z" _fill="#fff"/>'
  }
})
