<template>
    <component
        :is="href ? 'a' : tag"
        class="z-caption"
        :class="classObject"
        :href="href"
        :style="`line-height: ${lineHeight}`"
    >
        <z-icon
            v-if="icon"
            :name="icon"
            :width="iconSize ? iconSize :'44'"
            :height="iconSize ? iconSize :'44'"
            class="z-caption__icon"
        />
        <span class="z-caption__text"><slot /></span>
        <span
            class="z-caption__arrow"
            v-if="href"
        >
            <z-icon
                name="arrow-long"
                dir="right"
                width="16"
                height="16"
            />
        </span>
    </component>
</template>

<script>
export default {
    name: 'z-caption',
    props: {
        tag: {
            type: String,
            default: 'span',
            validator: prop => ['a', 'span', 'p', 'caption', 'figcaption', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(prop)
        },
        weight: {
            type: String,
            validator: prop => ['100', '200', '300', '400', '500', '600', '700', '800', '900'].includes(prop)
        },
        size: {
            type: String,
            default: '3xl',
            validator: prop => ['5xl', '4xl', '3xl', '2xl', 'xl', 'l', 'm', 's', 'xs'].includes(prop)
        },
        italic: Boolean,
        uppercase: Boolean,
        lowercase: Boolean,
        decorRight: Boolean,
        decorLeft: Boolean,
        lineHeight: String,
        icon: String,
        theme: {
            type: String,
            validator: prop => ['link', 'white', 'default'].includes(prop)
        },
        href: [String, Boolean],
        iconSize: String
    },
    computed: {
        classObject () {
            let arr = [
                {
                    'u-italic': this.italic,
                    'u-uppercase': this.uppercase,
                    'u-lowercase': this.lowercase,
                    'z-caption--decor-right': this.decorRight,
                    'z-caption--decor-left': this.decorLeft,
                    'z-caption--theme-link': this.href
                }
            ]

            if (this.size) arr.push(`z-caption--size-${this.size}`)
            if (this.color) arr.push(`z-caption--color-${this.color}`)
            if (this.weight) arr.push(`z-caption--weight-${this.weight}`)
            if (this.theme) arr.push(`z-caption--theme-${this.theme}`)

            return arr
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
