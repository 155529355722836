/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-long': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.642 4.249a.471.471 0 01.159-.108.499.499 0 01.197-.04m-.356.148l-7.396 7.396a.5.5 0 10.707.707l6.545-6.544v13.588a.5.5 0 101 0V5.807l6.544 6.545a.5.5 0 00.707-.707L12.35 4.247a.499.499 0 00-.35-.147" _fill="#0077C8"/></g>'
  }
})
