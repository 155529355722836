/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'telegram': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M20.678 24.425l-6.454-5.124c1.832-1.704 7.802-7.264 8.093-7.545h0c.152-.147.283-.316.35-.503.068-.186.097-.49-.14-.724-.21-.208-.485-.208-.652-.18-.186.03-.376.112-.555.225h0l-.01.006-10.522 6.92-3.897-1.358-.007-.003a1.07 1.07 0 01-.204-.116c-.123-.09-.17-.171-.18-.23a.508.508 0 01.136-.135 1.272 1.272 0 01.256-.137l.014-.006a.058.058 0 01.002 0l.009-.004.01-.004 16.874-6.855.01-.004.008-.003h.001l.006-.004.033-.013c.03-.012.074-.029.127-.046.111-.037.242-.07.36-.08.08-.006.125.001.145.006a.306.306 0 01.008.053L21.515 24.14a.788.788 0 01-.194.247.471.471 0 01-.239.109.783.783 0 01-.404-.07zM6.004 15.85c-.064-.523.735-.805.735-.805l-.735.805zm16.038-4.515h-.004.004zm2.442-2.844l.004.008-.004-.008z" _fill="#0077C8" _stroke="#0077C8"/>'
  }
})
