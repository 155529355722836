export const bondsData = [
    {
        title: 'Еврооблигации',
        bonds: [
            {
                name: 'Выпуски в обращении',
                id: 'outstanding',
                isin_footnote: true,
                items: [
                    {
                        tools: 'Еврооблигации 2026 (LPN)', // Инструмент
                        isin: 'XS2393505008; US55315NAE31', // ISIN
                        placement: '27.10.2021', // Дата размещения
                        redemption: '27.10.2026', // Дата погашения
                        coupon_payment: '27 октября и 27 апреля', // Даты выплаты купона
                        nearest: '11.10.2024', // Ближайшая дата отсечки
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: '500 млн долл.⁴', // Объем выпуска
                        coupon_rate: '2,80 %', // Ставка купона
                        showProspect: true,
                        showLink: true,
                        showUpdate: true,
                        link: 'https://www.moex.com/ru/issue.aspx?board=TQCB&code=XS2393505008',
                        prospect: '/files/en/investors/debt/bonds/final-prospectus-2026.pdf', // Проспект
                        update_link: '/files/en/investors/debt/bonds/update-2026.pdf',
                        prospect_size: '7.1 МБ',
                        update_size: '593 КБ'
                    },
                    {
                        tools: 'Еврооблигации 2025 (LPN)', // Инструмент
                        isin: 'XS2134628069; US55315NAD57', // ISIN
                        placement: '11.09.2020', // Дата размещения
                        redemption: '11.09.2025', // Дата погашения
                        coupon_payment: '11 сентября и 11 марта', // Даты выплаты купона
                        nearest: '27.08.2024', // Ближайшая дата отсечки
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: '500 млн долл.³', // Объем выпуска
                        coupon_rate: '2,550 %', // Ставка купона
                        showProspect: true,
                        showLink: true,
                        showUpdate: true,
                        link: 'https://www.moex.com/ru/issue.aspx?board=TQOD&code=XS2134628069&utm_source=www.moex.com&utm_term=XS2134628069#/bond_4', // Котировки бумаг
                        prospect: '/files/en/investors/debt/bonds/final-prospectus-2025.pdf', // Проспект
                        update_link: '/files/en/investors/debt/bonds/update-2025.pdf',
                        prospect_size: '5.8 МБ',
                        update_size: '214 КБ'
                    },
                    {
                        tools: 'Еврооблигации 2024 (LPN)', // Инструмент
                        isin: 'XS2069992258; US55315NAC74', // ISIN
                        placement: '28.10.2019', // Дата размещения
                        redemption: '28.10.2024', // Дата погашения
                        coupon_payment: '28 октября и 28 апреля', // Даты выплаты купона
                        nearest: '11.10.2024', // Ближайшая дата отсечки
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: '750 млн долл.', // Объем выпуска
                        coupon_rate: '3,375 %', // Ставка купона
                        showProspect: true,
                        showLink: true,
                        showUpdate: true,
                        link: 'https://www.moex.com/ru/issue.aspx?board=TQOD&code=XS2069992258&utm_source=www.moex.com&utm_term=XS2069992258#/bond_4', // Котировки бумаг
                        prospect: '/files/en/investors/debt/bonds/final-prospectus-2024.pdf', // Проспект
                        update_link: '/files/en/investors/debt/bonds/update-2024.pdf',
                        prospect_size: '8.1 МБ',
                        update_size: '212 КБ'
                    }
                ]
            },
            {
                name: 'Погашенные выпуски',
                id: 'redeemed',
                items: [
                    {
                        tools: 'Еврооблигации 2023 (LPN)', // Инструмент
                        isin: 'XS1589324075; US55315NAA19', // ISIN
                        placement: '11.04.2017', // Дата размещения
                        redemption: '11.04.2023', // Дата погашения
                        coupon_payment: '11 октября и 11 апреля', // Даты выплаты купона
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: '1 000 млн долл.', // Объем выпуска
                        coupon_rate: '4,10 %', // Ставка купона
                        showProspect: true,
                        showLink: true,
                        prospect: 'https://ise-prodnr-eu-west-1-data-integration.s3-eu-west-1.amazonaws.com/legacy/Prospectus+-+Standalone_27d29664-cf99-4fa9-9c24-3f6c238f35f5.PDF', // Проспект
                        prospect_size: '2.7 МБ'
                    },
                    {
                        tools: 'Еврооблигации 2022 (LPN)', // Инструмент
                        isin: 'XS1298447019; US55314WAC82', // ISIN
                        placement: '14.10.2015', // Дата размещения
                        redemption: '14.10.2022', // Дата погашения
                        coupon_payment: '14 октября и 14 апреля', // Даты выплаты купона
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: '1 000 млн долл.', // Объем выпуска
                        coupon_rate: '6,625 %', // Ставка купона
                        showProspect: true,
                        showLink: true,
                        prospect: 'https://ise-prodnr-eu-west-1-data-integration.s3-eu-west-1.amazonaws.com/legacy/Prospectus+-+Standalone_ab8970eb-9084-47d9-b741-1da24c00b7d9.PDF', // Проспект
                        prospect_size: '5.9 МБ'
                    },
                    {
                        tools: 'Еврооблигации 2022 (LPN)', // Инструмент
                        isin: 'XS1622146758', // ISIN
                        placement: '08.06.2017', // Дата размещения
                        redemption: '08.04.2022', // Дата погашения
                        coupon_payment: '8 октября и 8 апреля', // Даты выплаты купона
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: '500 млн долл.', // Объем выпуска
                        showProspect: true,
                        showLink: true,
                        coupon_rate: '3,849 %' // Ставка купона
                    },
                    {
                        tools: 'Еврооблигации 2020 (LPN)', // Инструмент
                        isin: 'XS0982861287; US55314WAA27', // ISIN
                        placement: '28.10.2013', // Дата размещения
                        redemption: '28.10.2020', // Дата погашения
                        coupon_payment: '28 октября и 28 апреля', // Даты выплаты купона
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: '1 000 млн долл.', // Объем выпуска
                        showProspect: true,
                        showLink: true,
                        coupon_rate: '5,55 %' // Ставка купона
                    },
                    {
                        tools: 'Еврооблигации 2018 (LPN)', // Инструмент
                        isin: 'XS0922134712', // ISIN
                        placement: '30.04.2013', // Дата размещения
                        redemption: '30.04.2018', // Дата погашения
                        coupon_payment: '30 октября и 30 апреля', // Даты выплаты купона
                        issuer: 'MMC Finance D.A.C.', // Эмитент
                        volume: '750 млн долл.', // Объем выпуска
                        showProspect: true,
                        showLink: true,
                        coupon_rate: '4,375 %' // Ставка купона
                    }
                ]
            }
        ],
        contacts: [
            {
                href: 'emea.at.debt@citi.com',
                description: 'Контакты платежного агента'
            },
            {
                href: 'nornickel@i2capmark.com',
                description: 'Контакты трасти'
            }
        ],
        footnotes: [
            '<sup>1</sup>&nbsp;International Securities Identification Number&nbsp;&mdash; 12-значный буквенно-цифровой код, однозначно идентифицирующий ценную бумагу (присваивается национальными нумерующими агентствами)',
            '<sup>2</sup>&nbsp;Дата, на&nbsp;которую фиксируется список локальных держателей еврооблигаций (т.е. держателей, права которых учитываются российскими депозитариями), владеющих еврооблигациями на&nbsp;соответствующую дату и&nbsp;имеющих право на&nbsp;получение выплаты по&nbsp;еврооблигациям',
            '<sup>3</sup>&nbsp;Объём выпуска за&nbsp;вычетом замещающих облигаций составляет 184,441 млн долл. США',
            '<sup>4</sup>&nbsp;Объём выпуска за&nbsp;вычетом замещающих облигаций составляет 166,515 млн долл. США'
        ]
    },
    {
        title: 'Локальные облигации',
        bonds: [
            {
                name: 'Выпуски в обращении',
                id: 'outstanding',
                items: [
                    {
                        tools: 'Биржевые облигации, БО‑001Р-07', // Инструмент
                        isin: 'RU000A1083A6', // ISIN
                        placement: '26.03.2024', // Дата размещения
                        redemption: '28.02.2029', // Дата погашения
                        coupon_payment: 'Каждый 30 дней, начиная с даты размещения', // Даты выплаты купона
                        issuer: 'ПАО «ГМК «Норильский никель»', // Эмитент
                        volume: '100 млрд руб.', // Объем выпуска
                        coupon_rate: 'КС ЦБ + 1,3%' // Ставка купона
                    },
                    {
                        tools: 'Биржевые облигации, ЗО26-Д', // Инструмент
                        isin: 'RU000A107C67', // ISIN
                        placement: '22.12.2023', // Дата размещения
                        redemption: '27.10.2026', // Дата погашения
                        coupon_payment: '27 октября и 27 апреля', // Даты выплаты купона
                        issuer: 'ПАО «ГМК «Норильский никель»', // Эмитент
                        volume: '333,485 млн долл. США', // Объем выпуска
                        coupon_rate: '2,8%' // Ставка купона
                    },
                    {
                        tools: 'Биржевые облигации, ЗО25-Д', // Инструмент
                        isin: 'RU000A107BL4', // ISIN
                        placement: '20.12.2023', // Дата размещения
                        redemption: '11.09.2025', // Дата погашения
                        coupon_payment: '11 сентября и 11 марта', // Даты выплаты купона
                        issuer: 'ПАО «ГМК «Норильский никель»', // Эмитент
                        volume: '315,559 млн долл. США', // Объем выпуска
                        coupon_rate: '2,55%' // Ставка купона
                    },
                    {
                        tools: 'Биржевые облигации, БО‑09', // Инструмент
                        isin: 'RU000A1069N8', // ISIN
                        placement: '24.05.2023', // Дата размещения
                        redemption: '17.05.2028', // Дата погашения
                        coupon_payment: 'Каждый 91 день, начиная с даты размещения', // Даты выплаты купона
                        issuer: 'ПАО «ГМК «Норильский никель»', // Эмитент
                        volume: '60 млрд руб.', // Объем выпуска
                        coupon_rate: 'RUONIA + 1,3%' // Ставка купона
                    },
                    {
                        tools: 'Биржевые облигации, БО 001Р-06-CNY', // Инструмент
                        isin: 'RU000A105NL3', // ISIN
                        placement: '22.12.2022', // Дата размещения
                        redemption: '18.06.2026 (25.12.2025 оферта)', // Дата погашения
                        coupon_payment: 'Каждый 91 день, начиная с даты размещения', // Даты выплаты купона
                        issuer: 'ПАО «ГМК «Норильский никель»', // Эмитент
                        volume: '5 млрд кит. юаней', // Объем выпуска
                        coupon_rate: 'LPR 1Y + 0,1%' // Ставка купона
                    },
                    {
                        tools: 'Биржевые облигации, БО 001Р-05-CNY', // Инструмент
                        isin: 'RU000A105ML5', // ISIN
                        placement: '19.12.2022', // Дата размещения
                        redemption: '15.12.2025', // Дата погашения
                        coupon_payment: 'Каждые 182 дня, начиная с даты размещения', // Даты выплаты купона
                        issuer: 'ПАО «ГМК «Норильский никель»', // Эмитент
                        volume: '4 млрд кит. юаней', // Объем выпуска
                        coupon_rate: '3,95%' // Ставка купона
                    },
                    {
                        tools: 'Биржевые облигации, БО‑001Р-02', // Инструмент
                        isin: 'RU000A105A61', // ISIN
                        placement: '11.10.2022', // Дата размещения
                        redemption: '05.10.2027 (14.10.2025 оферта)', // Дата погашения
                        coupon_payment: 'Каждые 182 дня, начиная с даты размещения', // Даты выплаты купона
                        issuer: 'ПАО «ГМК «Норильский никель»', // Эмитент
                        volume: '25 млрд руб.', // Объем выпуска
                        coupon_rate: '9,75 %' // Ставка купона
                    },
                    {
                        tools: 'Биржевые облигации, БО‑001Р-01', // Инструмент
                        isin: 'RU000A100VQ6', // ISIN
                        placement: '01.10.2019', // Дата размещения
                        redemption: '24.09.2024', // Дата погашения
                        coupon_payment: 'Каждые 182 дня, начиная с даты размещения', // Даты выплаты купона
                        issuer: 'ПАО «ГМК «Норильский никель»', // Эмитент
                        volume: '25 млрд руб.', // Объем выпуска
                        coupon_rate: '7,20 %' // Ставка купона
                    }
                ]
            },
            {
                name: 'Погашенные выпуски',
                id: 'redeemed',
                items: [
                    {
                        tools: 'Биржевые облигации, БО-01', // Инструмент
                        isin: 'RU000A0JTPV9', // ISIN
                        placement: '28.02.2013', // Дата размещения
                        redemption: '25.02.2016', // Дата погашения
                        coupon_payment: 'Каждые 182 дня, начиная с даты размещения', // Даты выплаты купона
                        issuer: 'ОАО «ГМК «Норильский никель»', // Эмитент
                        volume: '10 млрд руб.', // Объем выпуска
                        coupon_rate: '7,90 %' // Ставка купона
                    },
                    {
                        tools: 'Биржевые облигации, БО-02', // Инструмент
                        isin: 'RU000A0JTPX5', // ISIN
                        placement: '28.02.2013', // Дата размещения
                        redemption: '25.02.2016', // Дата погашения
                        coupon_payment: 'Каждые 182 дня, начиная с даты размещения', // Даты выплаты купона
                        issuer: 'ОАО «ГМК «Норильский никель»', // Эмитент
                        volume: '10 млрд руб.', // Объем выпуска
                        coupon_rate: '7,90 %' // Ставка купона
                    },
                    {
                        tools: 'Биржевые облигации, БО-03', // Инструмент
                        isin: 'RU000A0JQYY1', // ISIN
                        placement: '03.08.2010', // Дата размещения
                        redemption: '30.07.2013', // Дата погашения
                        coupon_payment: 'Каждые 182 дня, начиная с даты размещения', // Даты выплаты купона
                        issuer: 'ОАО «ГМК «Норильский никель»', // Эмитент
                        volume: '15 млрд руб.', // Объем выпуска
                        coupon_rate: '7 %' // Ставка купона
                    },
                    {
                        tools: 'Биржевые облигации, БО-04', // Инструмент
                        isin: 'RU000A0JTPY3', // ISIN
                        placement: '28.02.2013', // Дата размещения
                        redemption: '25.02.2016', // Дата погашения
                        coupon_payment: 'Каждые 182 дня, начиная с даты размещения', // Даты выплаты купона
                        issuer: 'ОАО «ГМК «Норильский никель»', // Эмитент
                        volume: '15 млрд руб.', // Объем выпуска
                        coupon_rate: '7,90 %' // Ставка купона
                    },
                    {
                        tools: 'Биржевые облигации, БО-05', // Инструмент
                        isin: 'RU000A0JW5C7', // ISIN
                        placement: '19.02.2016', // Дата размещения
                        redemption: '12.02.2021', // Дата погашения
                        coupon_payment: 'Каждые 182 дня, начиная с даты размещения', // Даты выплаты купона
                        issuer: 'ПАО «ГМК «Норильский никель»', // Эмитент
                        volume: '15 млрд руб.', // Объем выпуска
                        coupon_rate: '11,60 %' // Ставка купона
                    }
                ]
            }
        ]
    }
]
