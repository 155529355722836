<template>
<div class="annual-report-block" v-if="data">
    <div class="annual-report-block__header" v-if="!!this.$slots.header && hasData">
        <slot name="header" />
    </div>
    <div class="row">
        <div :class="colClass" v-if="data['annual-report'] && data['annual-report'].arFile && data['annual-report'].arFile.link && reportsList.includes('ar')">
            <report-block
                :horizontal="isHorizontal"
                :data="{
                    image: data['annual-report'].arImg.link,
                    name: data['annual-report'].arFile.name || `${text['annual-report']} ${data['annual-report'].name}`,
                    fileLink: data['annual-report'].arFile.link,
                    onlinelink: data['annual-report'].arUrl.value,
                    taggedFileLink: data['annual-report'].tvArFile.link
                }"
            />
        </div>
        <div :class="colClass" v-if="data.csr && data.csr.csrFile && data.csr.csrFile.link && reportsList.includes('csr')">
            <report-block
                :horizontal="isHorizontal"
                :data="{
                    image: data.csr.csrImg.link,
                    name: data.csr.csrFile.name || `${text.csr} ${data.csr.name}`,
                    fileLink: data.csr.csrFile.link,
                    onlinelink: data.csr.csrUrl.value
                }"
            />
        </div>
        <div :class="colClass" v-if="data.brochure && data.brochure.brochureFile && data.brochure.brochureFile.link && reportsList.includes('brochure')">
            <report-block
                :horizontal="isHorizontal"
                :data="{
                    image: data.brochure.brochureImg.link,
                    name: data.brochure.brochureFile.name || text.brochure,
                    fileLink: data.brochure.brochureFile.link
                }"
            />
        </div>
        <div
            :class="colClass"
            v-if="data['white-book'] && data['white-book'].wbFile && data['white-book'].wbFile.link && reportsList.includes('white-book')"
        >
            <report-block
                :data="{
                    image: data['white-book'].wbImg.link,
                    name: data['white-book'].wbFile.name || text['white-book'],
                    fileLink: data['white-book'].wbFile.link
                }"
            />
        </div>
        <div
            :class="colClass"
            v-if="data['corporate-social-politic'] && data['corporate-social-politic'].cspFile && data['corporate-social-politic'].cspFile.link && reportsList.includes('corporate-social-politic')"
        >
            <report-block
                :data="{
                    image: data['corporate-social-politic'].cspImg.link,
                    name: data['corporate-social-politic'].cspFile.name || text['corporate-social-politic'],
                    fileLink: data['corporate-social-politic'].cspFile.link
                }"
            />
        </div>
        <template v-if="data.additionalReports && data.additionalReports.length">
            <template v-for="(item, index) in data.additionalReports">
                <div
                    v-if="reportsList.includes(item.type.xmlId)"
                    :class="colClass"
                    :key="index"
                >
                    <report-block
                        :data="{
                            image: item.image.link,
                            name: item.name,
                            fileLink: item.file.link,
                            onlinelink: item.url.value
                        }"
                    />
                </div>
            </template>
        </template>
    </div>
</div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'annual-report-block',
    props: {
        propsData: {
            type: Object
        },
        colClass: {
            type: String,
            default: 'col-default-6 col-mobile-12'
        },
        horizontal: {
            type: Boolean,
            default: false
        },
        source: {
            type: String,
            default: 'annual-reports-block'
        },
        reportsList: {
            type: Array,
            default: () => ['brochure', 'white-book', 'climate-report', 'social-policy', 'analytical-report', 'csr', 'ar', 'corporate-social-politic', '']
        }
    },
    computed: {
        isHorizontal () {
            if (this.device !== 'mobile' && this.device !== 'v-tablet') return this.horizontal
            return false
        },
        hasData () {
            return (
                this.data &&
                (this.data.brochure && this.data.brochure.brochureImg && this.data.brochure.brochureImg.link) ||
                (this.data['annual-report'] && this.data['annual-report'].arImg && this.data['annual-report'].arImg.link) ||
                (this.data.csr && this.data.csr.csrFile && this.data.csr.csrFile.link) ||
                (this.data['white-book'] && this.data['white-book'].wbFile && this.data['white-book'].wbFile.link)
            )
        }
    },
    data () {
        return {
            data: null,
            text: {
                'annual-report': localize({
                    ru: 'Годовой отчет',
                    en: 'Annual report'
                }),
                brochure: localize({
                    ru: 'Брошюра',
                    en: 'Brochure'
                }),
                csr: localize({
                    ru: 'Отчет об устойчивом развитии',
                    en: 'Sustainability report'
                }),
                'white-book': localize({
                    ru: 'Белая книга',
                    en: 'White paper'
                }),
                'corporate-social-politic': localize({
                    ru: 'Корпоративная социальная политика',
                    en: 'Сorporate social politic'
                })
            }
        }
    },
    watch: {
        propsData (value) {
            console.log(value)
            this.data = this.propsData
        }
    },
    created () {
        if (this.propsData) {
            this.data = this.propsData
        } else if (this.$root.app.components[this.source]) {
            this.data = this.$root.app.components[this.source]
        }
    }
}
</script>

<style lang="scss">
.annual-report-block {
    padding-bottom: 24px;

    @include breakpoint(tablet) {
        @include margin-level(top, XL);
    }
}
</style>
