<template>
    <div class="repots-results" v-if="data">
        <template v-if="!isFinancialEmpty">
        <!-- <template v-if="
            (data['reports-and-results'].ar2DataBook.link || data['reports-and-results'].ar4DataBook.link) ||
            (data['reports-and-results'].fr2PressReleases.detailPageUrl || data['reports-and-results'].fr4PressReleases.detailPageUrl) ||
            (data['reports-and-results'].fr2FinancialStatementsUsd.link || data['reports-and-results'].fr4FinancialStatementsUsd.link) ||
            ($root.lang === 'ru' && (data['reports-and-results'].fr2FinancialStatementsRub.link || data['reports-and-results'].fr4FinancialStatementsRub.link)) ||
            (data['reports-and-results'].fr2Presentation.link || data['reports-and-results'].fr4Presentation.link) ||
            (data['reports-and-results'].fr2CanfCallInfo.link || data['reports-and-results'].fr4CanfCallInfo.link) ||
            (data['reports-and-results'].fr2CanfCallRecord.link || data['reports-and-results'].fr4CanfCallRecord.link)
        "> -->
            <z-caption
                decor-left
                tag="h2"
                size="m"
                uppercase
                weight="600"
            >
                <span v-html="text.finResults"></span>
            </z-caption>
            <z-table :columns="financialHead">
                <z-table-row v-if="data['reports-and-results'].ar2DataBook.link || data['reports-and-results'].ar4DataBook.link">
                    <z-table-col index="0"><span class="repots-results__title">Data book</span></z-table-col>
                    <z-table-col index="1">
                        <z-filelist size="l" v-if="data['reports-and-results'].ar2DataBook.link && !data['reports-and-results'].ar4DataBook.link">
                            <z-filelist-item
                                :file-size="data['reports-and-results'].ar2DataBook.size"
                                icon-name="file/xlsx"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].ar2DataBook.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.downloadLinkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                    <z-table-col index="2">
                        <z-filelist size="l" v-if="data['reports-and-results'].ar4DataBook.link">
                            <z-filelist-item
                                :file-size="data['reports-and-results'].ar4DataBook.size"
                                icon-name="file/xlsx"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].ar4DataBook.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.downloadLinkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                </z-table-row>
                <z-table-row v-if="data['reports-and-results'].fr2PressReleases.detailPageUrl || data['reports-and-results'].fr4PressReleases.detailPageUrl">
                    <z-table-col index="0"><span class="repots-results__title">{{ text['press-releases'] }}</span></z-table-col>
                    <z-table-col index="1">
                        <z-filelist size="l" v-if="data['reports-and-results'].fr2PressReleases.detailPageUrl">
                            <z-filelist-item
                                icon-name="file/book"
                            >
                                <template v-slot:fileinfo>
                                    {{ text['press-release'] }}
                                </template>
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr2PressReleases.detailPageUrl">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                    <z-table-col index="2">
                        <z-filelist size="l" v-if="data['reports-and-results'].fr4PressReleases.detailPageUrl">
                            <z-filelist-item
                                icon-name="file/book"
                            >
                                <template v-slot:fileinfo>
                                    {{ text['press-release'] }}
                                </template>
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr4PressReleases.detailPageUrl">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                </z-table-row>
                <z-table-row v-if="data['reports-and-results'].fr2FinancialStatementsUsd.link || data['reports-and-results'].fr4FinancialStatementsUsd.link || data['reports-and-results'].fr2FinancialStatementsUsdXbrl.link || data['reports-and-results'].fr4FinancialStatementsUsdXbrl.link">
                    <z-table-col index="0"><span class="repots-results__title">{{ text.finResUsd }}</span></z-table-col>
                    <z-table-col index="1">
                        <z-filelist size="l" v-if="data['reports-and-results'].fr2FinancialStatementsUsd.link || data['reports-and-results'].fr2FinancialStatementsUsdXbrl.link">
                            <z-filelist-item
                                v-if="data['reports-and-results'].fr2FinancialStatementsUsd.link"
                                :file-size="data['reports-and-results'].fr2FinancialStatementsUsd.size"
                                icon-name="file/pdf"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr2FinancialStatementsUsd.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                            <z-filelist-item
                                v-if="data['reports-and-results'].fr2FinancialStatementsUsdXbrl.link"
                                :file-size="data['reports-and-results'].fr2FinancialStatementsUsdXbrl.size"
                                icon-name="file/xbrl"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr2FinancialStatementsUsdXbrl.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.downloadLinkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                    <z-table-col index="2">
                        <z-filelist size="l" v-if="data['reports-and-results'].fr4FinancialStatementsUsd.link || data['reports-and-results'].fr4FinancialStatementsUsdXbrl.link">
                            <z-filelist-item
                                v-if="data['reports-and-results'].fr4FinancialStatementsUsd.link"
                                :file-size="data['reports-and-results'].fr4FinancialStatementsUsd.size"
                                icon-name="file/pdf"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr4FinancialStatementsUsd.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                            <z-filelist-item
                                v-if="data['reports-and-results'].fr4FinancialStatementsUsdXbrl.link"
                                :file-size="data['reports-and-results'].fr4FinancialStatementsUsdXbrl.size"
                                icon-name="file/xbrl"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr4FinancialStatementsUsdXbrl.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.downloadLinkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                </z-table-row>
                <z-table-row v-if="$root.lang === 'ru' && (data['reports-and-results'].fr2FinancialStatementsRub.link || data['reports-and-results'].fr4FinancialStatementsRub.link || data['reports-and-results'].fr2FinancialStatementsRubXbrl.link || data['reports-and-results'].fr4FinancialStatementsRubXbrl.link)">
                    <z-table-col index="0"><span class="repots-results__title">{{ text.finResRub }}</span></z-table-col>
                    <z-table-col index="1">
                        <z-filelist size="l" v-if="data['reports-and-results'].fr2FinancialStatementsRub.link">
                            <z-filelist-item
                                v-if="data['reports-and-results'].fr2FinancialStatementsRub.link"
                                :file-size="data['reports-and-results'].fr2FinancialStatementsRub.size"
                                icon-name="file/pdf"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr2FinancialStatementsRub.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                            <z-filelist-item
                                v-if="data['reports-and-results'].fr2FinancialStatementsRubXbrl.link"
                                :file-size="data['reports-and-results'].fr2FinancialStatementsRubXbrl.size"
                                icon-name="file/xbrl"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr2FinancialStatementsRubXbrl.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.downloadLinkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                    <z-table-col index="2">
                        <z-filelist size="l" v-if="data['reports-and-results'].fr4FinancialStatementsRub.link || data['reports-and-results'].fr4FinancialStatementsRubXbrl.link">
                            <z-filelist-item
                                v-if="data['reports-and-results'].fr4FinancialStatementsRub.link"
                                :file-size="data['reports-and-results'].fr4FinancialStatementsRub.size"
                                icon-name="file/pdf"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr4FinancialStatementsRub.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                            <z-filelist-item
                                v-if="data['reports-and-results'].fr4FinancialStatementsRubXbrl.link"
                                :file-size="data['reports-and-results'].fr4FinancialStatementsRubXbrl.size"
                                icon-name="file/xbrl"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr4FinancialStatementsRubXbrl.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.downloadLinkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                </z-table-row>
                <z-table-row v-if="data['reports-and-results'].fr2Presentation.link || data['reports-and-results'].fr4Presentation.link">
                    <z-table-col index="0"><span class="repots-results__title">{{ text.presentation }}</span></z-table-col>
                    <z-table-col index="1">
                        <z-filelist size="l" v-if="data['reports-and-results'].fr2Presentation.link">
                            <z-filelist-item
                                :file-size="data['reports-and-results'].fr2Presentation.size"
                                icon-name="file/pdf"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr2Presentation.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                    <z-table-col index="2">
                        <z-filelist size="l" v-if="data['reports-and-results'].fr4Presentation.link">
                            <z-filelist-item
                                :file-size="data['reports-and-results'].fr4Presentation.size"
                                icon-name="file/pdf"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr4Presentation.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                </z-table-row>
                <z-table-row v-if="data['reports-and-results'].fr2CanfCallInfo.link || data['reports-and-results'].fr4CanfCallInfo.link">
                    <z-table-col index="0"><span class="repots-results__title">{{ text.callInfo }}</span></z-table-col>
                    <z-table-col index="1">
                        <z-filelist size="l" v-if="data['reports-and-results'].fr2CanfCallInfo.link">
                            <z-filelist-item
                                :file-size="data['reports-and-results'].fr2CanfCallInfo.size"
                                icon-name="file/pdf"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr2CanfCallInfo.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                    <z-table-col index="2">
                        <z-filelist size="l" v-if="data['reports-and-results'].fr4CanfCallInfo.link">
                            <z-filelist-item
                                :file-size="data['reports-and-results'].fr4CanfCallInfo.size"
                                icon-name="file/pdf"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr4CanfCallInfo.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                </z-table-row>
                <z-table-row v-if="data['reports-and-results'].fr2CanfCallRecord.link || data['reports-and-results'].fr4CanfCallRecord.link">
                    <z-table-col index="0"><span class="repots-results__title">{{ text.callRecord }}</span></z-table-col>
                    <z-table-col index="1">
                        <z-filelist size="l" v-if="data['reports-and-results'].fr2CanfCallRecord.link">
                            <z-filelist-item
                                :file-size="data['reports-and-results'].fr2CanfCallRecord.size"
                                icon-name="file/mp3"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr2CanfCallRecord.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.callLinkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                    <z-table-col index="2">
                        <z-filelist size="l" v-if="data['reports-and-results'].fr4CanfCallRecord.link">
                            <z-filelist-item
                                :file-size="data['reports-and-results'].fr4CanfCallRecord.size"
                                icon-name="file/mp3"
                            >
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].fr4CanfCallRecord.link" target="_blank">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.callLinkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                </z-table-row>
            </z-table>
        </template>

        <template v-if="(
            data['reports-and-results'].pr1PressRelease.detailPageUrl ||
            data['reports-and-results'].pr2PressRelease.detailPageUrl ||
            data['reports-and-results'].pr3PressRelease.detailPageUrl ||
            data['reports-and-results'].pr4PressRelease.detailPageUrl
        )">
            <z-caption
                decor-left
                tag="h2"
                size="m"
                uppercase
                weight="600"
                class="u-top-margin--m"
            >
                <span v-html="text.prResults"></span>
            </z-caption>
            <z-table :columns="productionHead">
                <z-table-row>
                    <z-table-col index="0"><span class="repots-results__title">{{ text['press-releases'] }}</span></z-table-col>
                    <z-table-col index="1">
                        <z-filelist size="l" v-if="data['reports-and-results'].pr1PressRelease.detailPageUrl">
                            <z-filelist-item
                                icon-name="file/book"
                            >
                                <template v-slot:fileinfo>
                                    {{ text['press-release'] }}
                                </template>
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].pr1PressRelease.detailPageUrl">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                    <z-table-col index="2">
                        <z-filelist size="l" v-if="data['reports-and-results'].pr2PressRelease.detailPageUrl">
                            <z-filelist-item
                                icon-name="file/book"
                            >
                                <template v-slot:fileinfo>
                                    {{ text['press-release'] }}
                                </template>
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].pr2PressRelease.detailPageUrl">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                    <z-table-col index="3">
                        <z-filelist size="l" v-if="data['reports-and-results'].pr3PressRelease.detailPageUrl">
                            <z-filelist-item
                                icon-name="file/book"
                            >
                                <template v-slot:fileinfo>
                                    {{ text['press-release'] }}
                                </template>
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].pr3PressRelease.detailPageUrl">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                    <z-table-col index="4">
                        <z-filelist size="l" v-if="data['reports-and-results'].pr4PressRelease.detailPageUrl">
                            <z-filelist-item
                                icon-name="file/book"
                            >
                                <template v-slot:fileinfo>
                                    {{ text['press-release'] }}
                                </template>
                                <template v-slot:footer>
                                    <z-link :href="data['reports-and-results'].pr4PressRelease.detailPageUrl">
                                        <span
                                            class="text-size-s u-bold u-top-margin--3xs"
                                            v-html="text.linkText"></span>
                                    </z-link>
                                </template>
                            </z-filelist-item>
                        </z-filelist>
                    </z-table-col>
                </z-table-row>
            </z-table>
        </template>

        <div class="u-top-margin--2xl">
            <annual-report-block
                col-class="col-default-3 col-v-tablet-6 col-mobile-12"
                :props-data="data.additional"
                :reports-list="['ar', 'csr', 'brochure', 'climate-report', 'white-book']"
            >
                <template #header>
                    <z-caption
                        decor-right
                        tag="h2"
                        size="3xl"
                        class="u-top-margin--2xs"
                    >
                        <span v-html="text.annualReports"></span>
                    </z-caption>
                </template>
            </annual-report-block>
        </div>

        <presentations class="u-top-margin--2xl" :data="data['presentation-and-briefings']" />

        <card-link
            class="u-top-margin--3xl"
            :link="data['actual-reports-link']"
            v-if="data['actual-reports-link']"
        >
            {{ text.analytics }}
        </card-link>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import Presentations from './Presentations.vue'

export default {
    name: 'repots-results',
    props: {
        data: Object
    },
    computed: {
        isFinancialEmpty () {
            if (!this.data) return false
            return !(
                (this.data['reports-and-results'].ar2DataBook.link || this.data['reports-and-results'].ar4DataBook.link) ||
                (this.data['reports-and-results'].fr2PressReleases.detailPageUrl || this.data['reports-and-results'].fr4PressReleases.detailPageUrl) ||
                (this.data['reports-and-results'].fr2FinancialStatementsUsd.link || this.data['reports-and-results'].fr4FinancialStatementsUsd.link || this.data['reports-and-results'].fr2FinancialStatementsUsdXbrl.link || this.data['reports-and-results'].fr4FinancialStatementsUsdXbrl.link) ||
                (this.$root.lang === 'ru' && (this.data['reports-and-results'].fr2FinancialStatementsRub.link || this.data['reports-and-results'].fr4FinancialStatementsRub.link || this.data['reports-and-results'].fr2FinancialStatementsRubXbrl.link || this.data['reports-and-results'].fr4FinancialStatementsRubXbrl.link)) ||
                (this.data['reports-and-results'].fr2Presentation.link || this.data['reports-and-results'].fr4Presentation.link) ||
                (this.data['reports-and-results'].fr2CanfCallInfo.link || this.data['reports-and-results'].fr4CanfCallInfo.link) ||
                (this.data['reports-and-results'].fr2CanfCallRecord.link || this.data['reports-and-results'].fr4CanfCallRecord.link)
            )
        }
    },
    components: {
        Presentations
    },
    data () {
        return {
            financialHead: [
                localize({
                    ru: 'Финансовые результаты',
                    en: 'Financial results'
                }),
                localize({
                    ru: '6M',
                    en: '6M'
                }),
                localize({
                    ru: '12M',
                    en: '12M'
                })
            ],
            productionHead: [
                localize({
                    ru: 'Производственные результаты',
                    en: 'Production results'
                }),
                localize({
                    ru: '3M',
                    en: '3M'
                }),
                localize({
                    ru: '6M',
                    en: '6M'
                }),
                localize({
                    ru: '9M',
                    en: '9M'
                }),
                localize({
                    ru: '12M',
                    en: '12M'
                })
            ],
            text: {
                finResults: localize({
                    ru: 'Финансовые результаты',
                    en: 'Financial results'
                }),
                prResults: localize({
                    ru: 'Производственные результаты',
                    en: 'Production results'
                }),
                linkText: localize({
                    ru: 'Смотреть',
                    en: 'Learn more'
                }),
                downloadLinkText: localize({
                    ru: 'Скачать',
                    en: 'Download'
                }),
                callLinkText: localize({
                    ru: 'Слушать',
                    en: 'Listen'
                }),
                'press-releases': localize({
                    ru: 'Пресс-релизы',
                    en: 'Press releases'
                }),
                'press-release': localize({
                    ru: 'Пресс-релиз',
                    en: 'Press release'
                }),
                finResUsd: localize({
                    ru: 'Финансовая отчетность (USD)',
                    en: 'Financial reporting (USD)'
                }),
                finResRub: localize({
                    ru: 'Финансовая отчетность (RUB)',
                    en: 'Financial reporting (RUB)'
                }),
                presentation: localize({
                    ru: 'Презентация',
                    en: 'Presentation'
                }),
                callInfo: localize({
                    ru: 'Номера дозвона',
                    en: 'Dial numbers'
                }),
                callRecord: localize({
                    ru: 'Запись конференц-звонка',
                    en: 'Conference call recording'
                }),
                annualReports: localize({
                    ru: 'Годовые отчеты',
                    en: 'Annual reports'
                }),
                analytics: localize({
                    ru: 'Аналитические отчеты по сырьевому рынку',
                    en: 'Commodity research'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.repots-results {
    .z-table-col  {
       vertical-align: top; 
    }
    &__title {
        display: block;
        margin-top: 12px;
    }
}
</style>
