/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tags': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2 .4a.6.6 0 00-.6.6v10a.6.6 0 00.176.425l8.59 8.58a2.6 2.6 0 003.678 0l7.17-7.17-.424-.425.425.423a2.6 2.6 0 000-3.666l-8.59-8.59A.6.6 0 0012 .4H2zm18.165 11.587l-7.17 7.17a1.4 1.4 0 01-1.98 0l-.001-.001L2.6 10.752V1.6h9.151l8.413 8.413.001.001a1.4 1.4 0 010 1.973zM7 5a1 1 0 000 2h.01a1 1 0 100-2H7z" _fill="#0077C8"/>'
  }
})
