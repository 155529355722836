<template>
    <div class="modern-thumbs" v-if="data && data.length">
        <div class="modern-thumbs__container container" ref="container">
            <p
                v-if="title"
                v-html="title"
                class="modern-thumbs__title"
            ></p>

            <div class="modern-thumbs__thumbs" ref="thumbsContainer">
                <div
                    v-for="(item, index) in data"
                    :key="index"
                    ref="thumbs"
                    :class="['modern-thumbs__item', {'modern-thumbs__item--active': index === activeIndex}]"
                >
                    <div class="modern-thumbs__item-wrapper">
                        <div
                            ref="defaultContent"
                            @click="thumbClickHandler(index)"
                            class="modern-thumbs__item-content modern-thumbs__item-content--default"
                        >
                            <div class="modern-thumbs__item-body">
                                <p
                                    class="modern-thumbs__item-tag"
                                    v-html="item.tab || defaultTag"
                                ></p>
                                <p
                                    class="modern-thumbs__item-title"
                                    v-html="getFormatedTitle(item.name, 'default')"
                                ></p>
                            </div>
                        </div>

                        <div
                            ref="activeContent"
                            class="modern-thumbs__item-content modern-thumbs__item-content--active"
                        >
                            <p
                                class="modern-thumbs__item-tag"
                                v-html="item.tab || defaultTag"
                            ></p>
                            <z-link
                                ref="activeTitle"
                                :href="item.newsLink.value || item.newsConnection.detailPageUrl"
                                class="modern-thumbs__item-title"
                            >
                                <span v-html="getFormatedTitle(item.name, 'active')"></span>
                            </z-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'modern-thumbs',
    mixins: [mixinDevice],
    props: {
        data: Array,
        title: String,
        defaultTag: String,
        activeIndex: Number
    },
    data () {
        return {
            container: null,
            thumbContainer: null,
            thumbs: null,
            defaultContent: null,
            activeContent: null,
            activeTitle: null,
            thumbSizePercent: {
                desktop: {
                    default: 20,
                    active: 40
                },
                laptop: {
                    default: 25,
                    active: 50
                },
                tablet: {
                    default: 33,
                    active: 66
                },
                'v-tablet': {
                    default: 100,
                    active: 100
                },
                mobile: {
                    default: 100,
                    active: 100
                }
            },
            containerOffset: {
                desktop: 80,
                laptop: 40,
                tablet: 40,
                'v-tablet': 32,
                mobile: 16
            },
            titleSize: {
                desktop: {
                    default: 100,
                    active: 70
                },
                laptop: {
                    default: 100,
                    active: 75
                },
                tablet: {
                    default: 70,
                    active: 100
                },
                'v-tablet': {
                    default: 50,
                    active: 90
                },
                mobile: {
                    default: 50,
                    active: 90
                }
            }
        }
    },
    computed: {
        currentThumbSize () {
            return this.thumbSizePercent[this.device]
        },
        currentOffset () {
            return this.containerOffset[this.device]
        },
        currentTitleSize () {
            return this.titleSize[this.device]
        }
    },
    watch: {
        activeIndex: function (newVal, oldVal) {
            const direction = newVal > oldVal ? 'right' : 'left'
            const targetThumb = this.thumbs[newVal]
            const isVisibleTarget = this.isThumbVisible(targetThumb)

            if (!this.isMobile() && !this.isVTablet()) {
                if (direction === 'right') {
                    const nextThumb = this.thumbs[newVal + 1]
                    const isVisibleNext = !nextThumb || this.isThumbVisible(nextThumb)

                    if (!isVisibleNext) {
                        this.translateContainer(nextThumb, direction)
                    } else if (!isVisibleTarget) {
                        this.translateContainer(targetThumb, direction)
                    }
                } else if (direction === 'left') {
                    const prevThumb = this.thumbs[newVal - 1]
                    const isVisiblePrev = !prevThumb || this.isThumbVisible(prevThumb)

                    if (!isVisiblePrev) {
                        this.translateContainer(prevThumb, direction)
                    } else if (!isVisibleTarget) {
                        this.translateContainer(targetThumb, direction)
                    }
                }
            } else {
                this.translateContainer(targetThumb, direction)
            }

            this.collapseThumb(oldVal)
            this.expandThumb(newVal)
        },
        device: function (newVal, oldVal) {
            this.setThumbsSize()
        }
    },
    methods: {
        expandThumb (index) {
            const { defaultContent, activeContent, activeTitle } = this.getThumbContent(index)

            const tl = gsap.timeline({
                defaults: { ease: 'power1' },
                onStart: () => this.pointerEventsToggle('none'),
                onComplete: () => this.pointerEventsToggle('all')
            })

            // console.log(activeContent.querySelector(''));

            tl
                .to(this.thumbs[index], {
                    duration: 0.6,
                    width: `${this.currentThumbSize.active}%`
                })
                .to(activeTitle, {
                    duration: 0,
                    width: `${this.currentThumbSize.active}%`
                }, 0)
                .to(defaultContent, {
                    duration: 0.6,
                    opacity: 0,
                    pointerEvents: 'none'
                }, 0)
                .to(activeContent, {
                    duration: 0.6,
                    opacity: 1,
                    pointerEvents: 'all'
                }, 0.4)
        },
        collapseThumb (index) {
            const { defaultContent, activeContent } = this.getThumbContent(index)

            const tl = gsap.timeline({
                defaults: { ease: 'power1' },
                onStart: () => this.pointerEventsToggle('none'),
                onComplete: () => this.pointerEventsToggle('all')
            })

            tl

                .to(this.thumbs[index], {
                    duration: 0.6,
                    width: `${this.currentThumbSize.default}%`
                })
                .to(activeContent, {
                    duration: 0.6,
                    opacity: 0,
                    pointerEvents: 'none'
                }, 0.2)
                .to(defaultContent, {
                    duration: 0.6,
                    opacity: 1,
                    pointerEvents: 'all'
                }, 0.2)
        },
        getThumbContent (index) {
            const defaultContent = this.defaultContent[index]
            const activeContent = this.activeContent[index]
            const activeTitle = this.activeTitle[index]

            return { defaultContent, activeContent, activeTitle }
        },
        thumbClickHandler (index) {
            this.$emit('change', index + 1)
        },
        setThumbsSize () {
            this.thumbs.forEach(item => {
                item.style.width = `${this.currentThumbSize.default}%`
            })

            this.expandThumb(this.activeIndex)
        },
        pointerEventsToggle (events = 'all') {
            this.thumbs.forEach(item => gsap.to(item, { pointerEvents: events }))
        },
        isThumbVisible (thumb) {
            const rect = thumb.getBoundingClientRect()

            return (
                rect.left >= 0 &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            )
        },
        translateContainer (thumb, dir = 'right') {
            const thumbsContainerPos = this.thumbsContainer.getBoundingClientRect()
            const targetPos = thumb.getBoundingClientRect()
            let translateValue = dir === 'right'
                ? thumbsContainerPos.right - targetPos.right
                : -(targetPos.left - thumbsContainerPos.left)

            this.thumbsContainer.style.transform = `translateX(${translateValue}px)`
        },
        updatePosition () {
            setTimeout(() => {
                const containerPos = this.container.getBoundingClientRect()
                const thumbPos = this.thumbs[this.activeIndex].getBoundingClientRect()
                const thumbsContainerPos = this.thumbsContainer.getBoundingClientRect()

                let translateValue = thumbsContainerPos.right - thumbPos.right

                if (thumbsContainerPos.left + translateValue > containerPos.left + this.currentOffset) {
                    translateValue = 0
                }

                this.thumbsContainer.style.transform = `translateX(${translateValue}px)`
            }, 500)
        },
        getFormatedTitle (title, type = 'default') {
            return title.length > this.currentTitleSize[type]
                ? `${title.slice(0, this.currentTitleSize[type])}...`
                : title
        }
    },
    mounted () {
        this.container = this.$refs.container
        this.thumbsContainer = this.$refs.thumbsContainer
        this.thumbs = this.$refs.thumbs
        this.defaultContent = this.$refs.defaultContent
        this.activeContent = this.$refs.activeContent
        this.activeTitle = this.$refs.activeTitle

        this.setThumbsSize()
        window.addEventListener('resize', this.updatePosition)
    },
    destroyed () {
        window.removeEventListener('resize', this.updatePosition)
    }
}
</script>

<style lang="scss">
.modern-thumbs {
    $parent: &;

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        color: $token-colors-white;
        margin-bottom: 1.375rem;

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }

    &__thumbs {
        display: flex;
        transition: transform 0.5s ease;
    }

    &__item {
        flex-shrink: 0;
        height: 203px;
        padding: 0 4px;

        @include breakpoint(tablet) {
            height: 160px;
        }

        @include breakpoint(v-tablet) {
            height: 120px;
        }

        @include breakpoint(mobile) {
            height: 170px;
        }

        &:first-child {
            padding: 0 4px 0 0;
        }

        &:last-child {
            padding: 0 0 0 4px;
        }

        &:not(.modern-thumbs__item--active) {
            #{$parent}__item-content--active {
                opacity: 0 !important;
                pointer-events: none !important;
            }
        }
    }

    &__item-wrapper {
        height: 100%;
        color: $token-colors-white;
        background: rgba(6, 76, 118, 0.60);
        backdrop-filter: blur(10px);
        border-radius: 20px 20px 20px 0px;
        position: relative;
    }

    &__item-content {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        // transition: opacity 0.1s ease;
        overflow: hidden;

        &--default {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 1rem 1.5rem;
            cursor: pointer;

            @include breakpoint(mobile) {
                padding: 1rem;
            }

            #{$parent}__item-title {
                font-size: 16px;
                font-weight: 600;
                line-height: 120%;
                letter-spacing: -0.16px;
            }

            #{$parent}__item-tag {
                font-size: 14px;
            }
        }

        &--active {
            padding: 1.5rem 2rem;
            opacity: 0;
            pointer-events: none;

            @include breakpoint(tablet) {
                padding: 1rem 1.5rem;
            }

            @include breakpoint(mobile) {
                padding: 1rem;
            }

            #{$parent}__item-title {
                font-size: 36px;
                font-weight: 600;
                line-height: 120%;
                letter-spacing: -0.36px;
                color: $token-colors-white;
                transition: all $transition;

                &:hover {
                    color: $token-colors-white !important;
                    opacity: 0.7;
                }

                @include breakpoint(tablet) {
                    font-size: 26px;
                }

                @include breakpoint(v-tablet) {
                    font-size: 20px;
                }

                @include breakpoint(mobile) {
                    font-size: 18px;
                }
            }

            #{$parent}__item-tag {
                font-size: 16px;

                @include breakpoint(mobile) {
                    font-size: 14px;
                }
            }
        }
    }

    &__item-body {
        height: 65%;
    }

    &__item-tag {
        color: $token-colors-blue-30;
        margin-bottom: 0.5rem;
    }
}
</style>
