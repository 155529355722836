/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leader_big': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M45.224 41.584V77h2V41.214a6.787 6.787 0 002.731-1.867c1.138-1.267 1.82-2.92 1.82-4.451v-1H47.225v-6.502c4.447-1.815 9.563-1.644 13.794.789v3.414c4.551-1.707 9.444-.91 13.768 2.162l-1.024-1.82-3.3-6.145 4.324-4.325c-4.324-3.072-9.217-3.869-13.769-2.162V15.78a15.837 15.837 0 00-13.793-.696V11h-2v22.896H24.603c-2.746 0-5.26.432-7.11 1.66-1.926 1.28-2.993 3.323-2.993 6.168v11.379c0 2.942 2.61 5.552 5.552 5.552v-2c-1.838 0-3.552-1.715-3.552-3.552v-11.38c0-2.276.811-3.645 2.1-4.5 1.363-.905 3.402-1.327 6.003-1.327H45.224v3.688a4.524 4.524 0 01-.138.002H33.845v37.413h2V41.586h9.241c.046 0 .093 0 .138-.002zm2-2.564c.468-.264.885-.61 1.243-1.008a5.302 5.302 0 001.167-2.116H47.224v3.124zM21.328 24.086c0-3.453 2.553-6.349 6.12-6.349s6.121 2.896 6.121 6.349v1.82c0 3.453-2.554 6.349-6.12 6.349-3.594 0-6.121-3.038-6.121-6.463v-1.706zm6.12-4.349c-2.35 0-4.12 1.884-4.12 4.349v1.707c0 2.492 1.796 4.462 4.12 4.462 2.35 0 4.121-1.884 4.121-4.349v-1.82c0-2.465-1.77-4.349-4.12-4.349zM20.19 77V41.724h2V77h-2zm6.827-20.482V77h2V56.517h-2z" _fill="#0077C8"/>'
  }
})
