/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-mp3': {
    width: 37,
    height: 22,
    viewBox: '0 0 37 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.121 16.714h1.494V4.708h-2.142L6.73 13.744 3.003 4.708H.861v12.006h1.494V6.832l4.068 9.882h.612l4.086-9.882v9.882zm4.295 0h1.494v-4.77h3.33c2.376 0 3.762-1.656 3.762-3.618s-1.35-3.618-3.762-3.618h-4.824v12.006zm7.038-8.388c0 1.35-.972 2.286-2.394 2.286h-3.15V6.04h3.15c1.422 0 2.394.936 2.394 2.286zm2.644 6.624c.738 1.08 2.232 1.98 4.266 1.98 2.646 0 4.248-1.458 4.248-3.456 0-1.926-1.674-2.862-2.772-2.952 1.134-.198 2.592-1.062 2.592-2.79 0-2.034-1.764-3.204-4.068-3.204-1.854 0-3.24.81-4.104 1.854l.828.936c.882-.954 1.854-1.458 3.168-1.458 1.458 0 2.682.72 2.682 2.052 0 1.386-1.278 1.98-2.79 1.98-.486 0-1.044 0-1.242-.018v1.368c.18-.018.738-.018 1.242-.018 1.764 0 2.988.63 2.988 2.124 0 1.386-1.062 2.25-2.808 2.25-1.422 0-2.646-.648-3.348-1.584l-.882.936z" _fill="#0077C8"/>'
  }
})
