/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'goal': {
    width: 88,
    height: 88,
    viewBox: '0 0 88 88',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M44 12.75c-17.262 0-31.25 13.988-31.25 31.25S26.738 75.25 44 75.25 75.25 61.262 75.25 44c0-5.234-1.29-10.169-3.571-14.508a.75.75 0 011.328-.698A32.581 32.581 0 0176.75 44c0 18.09-14.66 32.75-32.75 32.75S11.25 62.09 11.25 44 25.91 11.25 44 11.25a32.581 32.581 0 0115.206 3.743.75.75 0 11-.698 1.328A31.081 31.081 0 0044 12.75zm19.595 5.955a.75.75 0 00-1.28-.531l-7.289 7.263a.75.75 0 00-.22.532v6.165L43.47 43.47a.75.75 0 001.06 1.06l11.336-11.335h6.166c.2 0 .39-.08.531-.22l7.264-7.29a.75.75 0 00-.532-1.28h-5.7v-5.7zm-6.229 12.99h4.355l5.768-5.79h-4.334l-5.789 5.79zm4.729-6.85v-4.334l-5.79 5.769v4.354l5.79-5.789zM21.893 44c0-12.183 9.924-22.107 22.107-22.107 2.728 0 5.33.491 7.735 1.4a.75.75 0 10.53-1.404A23.333 23.333 0 0044 20.393c-13.011 0-23.607 10.596-23.607 23.607S30.989 67.607 44 67.607 67.607 57.011 67.607 44c0-2.91-.524-5.692-1.496-8.265a.75.75 0 10-1.403.53 21.831 21.831 0 011.4 7.735c0 12.183-9.925 22.107-22.108 22.107S21.893 56.183 21.893 44zM44 31.036c-7.154 0-12.964 5.81-12.964 12.964 0 7.154 5.81 12.964 12.964 12.964 7.154 0 12.964-5.81 12.964-12.964 0-1.924-.408-3.746-1.175-5.399a.75.75 0 111.361-.63A14.265 14.265 0 0158.464 44c0 7.982-6.482 14.464-14.464 14.464-7.983 0-14.464-6.482-14.464-14.464 0-7.983 6.481-14.464 14.464-14.464 2.146 0 4.18.483 6.022 1.31a.75.75 0 11-.615 1.368A13.161 13.161 0 0044 31.036zm-.956 9.271a.75.75 0 00-.373-1.453A5.3 5.3 0 0038.679 44 5.335 5.335 0 0044 49.32a5.323 5.323 0 005.146-3.992.75.75 0 10-1.453-.373A3.823 3.823 0 0144 47.822 3.835 3.835 0 0140.18 44a3.8 3.8 0 012.864-3.693z" _fill="#0077C8"/>'
  }
})
