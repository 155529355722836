/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people-default': {
    width: 114,
    height: 114,
    viewBox: '0 0 114 114',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.574 95.428c-21.222-21.221-21.222-55.628 0-76.85 21.221-21.22 55.628-21.22 76.85 0 21.22 21.222 21.22 55.629 0 76.85-21.222 21.221-55.629 21.221-76.85 0zm-.849-77.698c-21.69 21.69-21.69 56.856 0 78.546 21.69 21.69 56.857 21.69 78.547 0 21.69-21.69 21.69-56.856 0-78.546-21.69-21.69-56.857-21.69-78.547 0zm38.434-.159c-7.075 0-12.934 6.419-12.934 14.456 0 8.084 5.882 15.35 12.934 15.35 3.518 0 6.743-1.8 9.105-4.644 2.362-2.843 3.829-6.695 3.829-10.706 0-8.038-5.842-14.456-12.934-14.456zM42.025 32.027c0-8.577 6.279-15.656 14.134-15.656 7.873 0 14.134 7.08 14.134 15.656 0 4.295-1.568 8.418-4.106 11.473-2.54 3.056-6.081 5.077-10.028 5.077-7.914 0-14.134-8.022-14.134-16.55zm14.134 22.62c-14.85 0-26.88 12.047-26.88 26.879v6.445c0 .5.102.84.246 1.08.143.235.354.416.65.557.63.298 1.558.376 2.729.376h7.152V76.192a.6.6 0 011.2 0v13.792h29.806V76.192a.6.6 0 011.2 0v13.792h7.133c.227 0 .442.002.648.004.35.004.673.007.987 0 .492-.013.883-.053 1.188-.144.294-.088.473-.212.59-.377.121-.171.226-.458.23-.962v-6.98c0-14.849-12.03-26.879-26.88-26.879zM40.685 91.183h38.71c.2 0 .402.002.603.004.367.004.73.007 1.063-.001.52-.013 1.04-.056 1.5-.193.472-.141.912-.39 1.226-.833.31-.436.446-.99.451-1.649v-6.986c0-15.513-12.567-28.08-28.08-28.08-15.512 0-28.079 12.586-28.079 28.08v6.445c0 .663.136 1.231.42 1.7.285.473.694.8 1.163 1.021.902.428 2.086.492 3.242.492h7.722a.582.582 0 00.06 0z" _fill="#0077C8"/>'
  }
})
