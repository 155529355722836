/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'faq-attention': {
    width: 33,
    height: 33,
    viewBox: '0 0 33 33',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.066 16.083c0-8.202 6.65-14.85 14.85-14.85 8.203 0 14.851 6.648 14.851 14.85s-6.648 14.85-14.85 14.85-14.85-6.648-14.85-14.85zM16.916 0C8.035 0 .835 7.2.835 16.083s7.2 16.083 16.083 16.083c8.882 0 16.083-7.2 16.083-16.083C33 7.201 25.8 0 16.917 0zm-2.733 13.126a2.854 2.854 0 115.468 0l-1.176 3.917a1.627 1.627 0 01-3.116 0l-1.176-3.917zm2.734-2.442a1.621 1.621 0 00-1.553 2.087l1.175 3.918a.394.394 0 00.756 0l1.175-3.918a1.621 1.621 0 00-1.553-2.087zm0 9.775a.887.887 0 100 1.774.887.887 0 000-1.774zm-2.12.887a2.12 2.12 0 114.24 0 2.12 2.12 0 01-4.24 0z" _fill="#0077C8"/>'
  }
})
