<template>
    <div class="buisness-map" ref="map">
        <div class="container">
            <page-tool
                :items="tabs"
                :defaultValue="this.defaultValue"
                type="tab"
                @change="changeTab"
                icon-size="48px"
            />
        </div>
        <div class="scrollable">
            <div class="buisness-map__img min-width-v-tablet">
                <map-ru v-if="lang === 'ru'" :class="currentImage" />
                <map-en v-if="lang === 'en'" :class="currentImage" />
            </div>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import MapRu from './svg/MapRu.vue'
import MapEn from './svg/MapEn.vue'

export default {
    name: 'buisness-map',
    components: {
        MapRu,
        MapEn
    },
    data () {
        return {
            lang: this.$root.lang,
            currentImage: 'map-all',
            defaultValue: 'all',
            tabs: [
                {
                    title: localize({
                        ru: 'Все',
                        en: 'All'
                    }),
                    id: 'all',
                    icon: 'map-all'
                },
                {
                    title: localize({
                        ru: 'Производственные активы',
                        en: 'Production asset'
                    }),
                    id: 'assets',
                    icon: 'map_asset'
                },
                {
                    title: localize({
                        ru: 'Проекты развития',
                        en: 'Development project'
                    }),
                    id: 'exploration',
                    icon: 'map_development'
                },
                {
                    title: localize({
                        ru: 'Сбытовые компании',
                        en: 'Sales company'
                    }),
                    id: 'sales',
                    icon: 'map_sales'
                },
                {
                    title: localize({
                        ru: 'Транзитные склады',
                        en: 'Transit warehouse (port)'
                    }),
                    id: 'logistic',
                    icon: 'map_ship'
                }
            ]
        }
    },
    methods: {
        changeTab (id) {
            this.currentImage = id
        },

        relocation (id, url) {
            const element = document.querySelector(`#${id}`)

            element.addEventListener('click', () => {
                url.includes('http') ? window.open(url, '_blank') : window.location.href = url
            })

            element.addEventListener('mousedown', ({which}) => {
                if (which === 2) {
                    window.open(url, '_blank')
                }
            })
        },

        rmListeners (id, url) {
            const element = document.querySelector(`#${id}`)

            element.removeEventListener('mousedown', ({which}) => {
                if (which === 2) {
                    window.open(url, '_blank')
                }
            })

            element.removeEventListener('click', () => {
                url.includes('http') ? window.open(url, '_blank') : window.location.href = url
            })
        }
    },
    computed: {
        IDArray () {
            return [
                {
                    id: 'pitsburg_link',
                    url: '/contacts/'
                },
                {
                    id: 'cug_link',
                    url: '/contacts/'
                },
                {
                    id: 'harjavalta_link',
                    url: 'https://www.nornickel.fi/'
                },
                {
                    id: 'kolsky_link',
                    url: 'https://www.kolagmk.ru/'
                },
                {
                    id: 'kasnoyarsk_link',
                    url: 'https://www.krastsvetmet.ru/'
                },
                {
                    id: 'zapolarny_link',
                    url: 'https://zf.nornickel.ru/'
                },
                {
                    id: 'buistrinskiy_link',
                    url: 'https://www.grkb.ru/'
                },
                {
                    id: 'shankhai_link',
                    url: '/contacts/'
                },
                {
                    id: 'hongkong_link',
                    url: '/contacts/'
                }
            ]
        }

    },
    beforeUnmount () {
        this.IDArray.forEach(({id, url}) => {
            this.rmListeners(id, url)
            this.rmListeners(`${id}_bg`, url)
        })
    },
    mounted () {
        this.IDArray.forEach(({id, url}) => {
            this.relocation(id, url)
            this.relocation(`${id}_bg`, url)
        })
        this.changeTab(this.defaultValue)
    }
}
</script>

<style lang="scss">
#hongkong-popup,
#shankhai-popup,
#buistrinskiy-popup,
#zapolarny-popup,
#kasnoyarsk-popup,
#kolsky-popup,
#harjavalta-popup,
#cug-popup,
#pitsburg-popup {
    display: none;
    position: relative;
    z-index: 100;
    cursor: default;

    [id$="_link_bg"] {
        cursor: pointer;
    }

    [id$="_link_bg"]:hover ~ [id$="_link"] {
        opacity: 0.7;
    }

    [id$="_link"]:hover{
        opacity: 0.7;
        cursor: pointer;
    }

    [id$="_link"] > path {
        cursor: pointer;
    }

    [id$="_link"] > g {
        cursor: pointer;
    }
}

#honkong-round,
#shankhai-round,
#buistrinskiy-round,
#zapolarny-round,
#kasnoyarsk-round,
#kolsky-round,
#harjavalta-round,
#cug-round,
#pitsburg-round {
    cursor: pointer;
}

.buisness-map {
    &__img {
        svg,
        img {
            width: 100%;
        }
    }

    #sales,
    #assets,
    #development,
    #logistics,
    #ports {
        opacity: 0.3;
    }

    svg.all {
        #sales,
        #assets,
        #development,
        #logistics,
        #ports {
            opacity: 1;
        }

        #honkong-round:hover #hongkong-popup,
        #cug-round:hover #cug-popup,
        #pitsburg-round:hover #pitsburg-popup,
        #shankhai-round:hover #shankhai-popup,
        #buistrinskiy-round:hover #buistrinskiy-popup,
        #zapolarny-round:hover #zapolarny-popup,
        #kasnoyarsk-round:hover #kasnoyarsk-popup,
        #kolsky-round:hover #kolsky-popup,
        #harjavalta-round:hover #harjavalta-popup,
        #cug-round:hover #cug-popup {
            display: block;
        }
    }

    svg.assets {
        #harjavalta-round:hover #harjavalta-popup,
        #kolsky-round:hover #kolsky-popup,
        #zapolarny-round:hover #zapolarny-popup,
        #kasnoyarsk-round:hover #kasnoyarsk-popup,
        #buistrinskiy-round:hover #buistrinskiy-popup {
            display: block;
        }

        #assets,
        #development {
            opacity: 1;
        }
    }

    svg.exploration {
        #development {
            opacity: 1;
        }

        #buistrinskiy-round:hover #buistrinskiy-popup{
            display: block;
        }
    }

    svg.logistic {
        #logistics,
        #ports {
            opacity: 1;
        }
    }

    svg.sales {
        #pitsburg-round:hover #pitsburg-popup,
        #cug-round:hover #cug-popup,
        #shankhai-round:hover #shankhai-popup,
        #honkong-round:hover #hongkong-popup {
            display: block;
        }

        #sales {
            opacity: 1;
        }
    }

    &__layer {
        opacity: 0.3;
        transition: opacity $transition;
        cursor: default;

        &.is-active {
            cursor: pionter;
            pointer-events: initial;
            opacity: 1;
        }
    }
}
</style>
