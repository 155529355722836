/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  ' map_delivery/truck': {
    width: 26,
    height: 20,
    viewBox: '0 0 26 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 .619C0 .277.277 0 .619 0H13.81a2.377 2.377 0 012.375 2.375v2.558a.619.619 0 01.173-.024h5.556c.789 0 1.493.416 1.872 1.11l.003.005 2.138 4.008.073.137v6.628h-2.316a3.592 3.592 0 01-7.097-.001h-.998a.55.55 0 01-.023 0H9.795a3.592 3.592 0 01-7.097 0H.62a.619.619 0 110-1.238h2.103a3.592 3.592 0 017.05 0h5.175V2.375a1.14 1.14 0 00-1.136-1.137H.618A.62.62 0 010 .62zm16.185 14.939V6.123a.619.619 0 00.173.024h2.889v4.819h5.515v4.593H23.66a3.592 3.592 0 00-7.05-.001h-.426zm8.176-5.83L22.698 6.61a.87.87 0 00-.784-.463h-1.429v3.58h3.876zm-20.447 6.21a2.353 2.353 0 11-.012.504.617.617 0 00.012-.504zm16.222-2.046a2.353 2.353 0 100 4.705 2.353 2.353 0 000-4.705zM0 4.785c0-.342.277-.619.619-.619h9.545a.62.62 0 110 1.238H.619A.619.619 0 010 4.785zm.619 2.42a.62.62 0 000 1.238h9.545a.62.62 0 100-1.238H.619z" _fill="#004C97"/>'
  }
})
