<template>
    <div class="listing">
        <div class="listing__list">
            <div class="listing__item" v-for="(item, index) in items" :key="index">
                <z-link :href="item.link" class="listing__link">
                    <template v-slot:left>
                        <picture class="listing__item-pic" v-if="item.logo">
                            <img :src="item.logo" class="listing__item-img" />
                        </picture>
                        <div class="listing__item-text">
                            <vue-raw :raw="item.text"/>
                        </div>
                        <div class="listing__item-value" v-if="item.value">
                            <vue-raw :raw="item.value"/>
                        </div>
                        <div class="listing__icon">
                            <z-icon
                                name="arrow-long"
                                dir="right"
                                width="16"
                                height="16"
                            ></z-icon>
                        </div>
                    </template>
                </z-link>
            </div>
        </div>
        <div class="listing__bottom" v-if="this.$slots.bottom">
            <slot name="bottom" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'listing',
    props: {
        aInfoLink: String,
        items: Array
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
