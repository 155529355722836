/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/text-rtf': {
    width: 32,
    height: 22,
    viewBox: '0 0 32 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.541 16.714h1.764l-3.24-4.896c1.638-.144 3.114-1.332 3.114-3.492 0-2.214-1.566-3.618-3.762-3.618H1.593v12.006h1.494v-4.77h2.394l3.06 4.77zm.09-8.388c0 1.35-.972 2.304-2.394 2.304h-3.15V6.04h3.15c1.422 0 2.394.936 2.394 2.286zm6.708 8.388h1.494V6.04h3.816V4.708H11.54V6.04h3.798v10.674zm7.295 0h1.494v-5.472h6.246V9.91h-6.246V6.04H30.5V4.708h-7.866v12.006z" _fill="#0077C8"/>'
  }
})
