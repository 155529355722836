import { localize } from '@/utils/i18n'

export const enterprises = [
    {
        'id': 'Норникель-ОЦО',
        'selected': false,
        'disabled': false,
        'text': 'Норникель-ОЦО'
    },
    {
        'id': 'ПАО «ГМК «Норильский никель»',
        'selected': false,
        'disabled': false,
        'text': 'ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 'ЗФ ПАО «ГМК «Норильский никель»',
        'selected': false,
        'disabled': false,
        'text': 'ЗФ ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 'АО «Кольская ГМК»',
        'selected': false,
        'disabled': false,
        'text': 'АО «Кольская ГМК»'
    },
    {
        'id': 'ООО «Печенгастрой»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Печенгастрой»'
    },
    {
        'id': 'АО «НОРМЕТИМПЭКС»',
        'selected': false,
        'disabled': false,
        'text': 'АО «НОРМЕТИМПЭКС»'
    },
    {
        'id': 'ООО «Норникель – Общий центр обслуживания»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норникель – Общий центр обслуживания»'
    },
    {
        'id': 'АТФ ПАО «ГМК «Норильский никель»',
        'selected': false,
        'disabled': false,
        'text': 'АТФ ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 'БТФ ПАО «ГМК «Норильский никель»',
        'selected': false,
        'disabled': false,
        'text': 'БТФ ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 'ЗТФ ПАО «ГМК «Норильский никель»',
        'selected': false,
        'disabled': false,
        'text': 'ЗТФ ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 'КТФ ПАО «ГМК «Норильский никель»',
        'selected': false,
        'disabled': false,
        'text': 'КТФ ПАО «ГМК «Норильский никель»'
    },
    {
        'id': '«Норильскэнерго» - филиал ПАО «ГМК «Норильский никель»',
        'selected': false,
        'disabled': false,
        'text': '«Норильскэнерго» - филиал ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 'АО «Лесосибирский порт»',
        'selected': false,
        'disabled': false,
        'text': 'АО «Лесосибирский порт»'
    },
    {
        'id': 'АО «АК «НордСтар»',
        'selected': false,
        'disabled': false,
        'text': 'АО «АК «НордСтар»'
    },
    {
        'id': 'АО «ЕРП»',
        'selected': false,
        'disabled': false,
        'text': 'АО «ЕРП»'
    },
    {
        'id': 'АО «КРП»',
        'selected': false,
        'disabled': false,
        'text': 'АО «КРП»'
    },
    {
        'id': 'АО «КСРЗ»',
        'selected': false,
        'disabled': false,
        'text': 'АО «КСРЗ»'
    },
    {
        'id': 'АО «Норильск Авиа»',
        'selected': false,
        'disabled': false,
        'text': 'АО «Норильск Авиа»'
    },
    {
        'id': 'АО «Норильскгазпром»',
        'selected': false,
        'disabled': false,
        'text': 'АО «Норильскгазпром»'
    },
    {
        'id': 'АО «Норильский комбинат»',
        'selected': false,
        'disabled': false,
        'text': 'АО «Норильский комбинат»'
    },
    {
        'id': 'АО «НТЭК»',
        'selected': false,
        'disabled': false,
        'text': 'АО «НТЭК»'
    },
    {
        'id': 'АО «Норильсктрансгаз»',
        'selected': false,
        'disabled': false,
        'text': 'АО «Норильсктрансгаз»'
    },
    {
        'id': 'АО «Полигон-техноген»',
        'selected': false,
        'disabled': false,
        'text': 'АО «Полигон-техноген»'
    },
    {
        'id': 'АО «Таймырская топливная компания»',
        'selected': false,
        'disabled': false,
        'text': 'АО «Таймырская топливная компания»'
    },
    {
        'id': 'МТФ ПАО «ГМК «Норильский никель»',
        'selected': false,
        'disabled': false,
        'text': 'МТФ ПАО «ГМК «Норильский никель»'
    },
    {
        'id': 'ООО «Инвест-Юг»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Инвест-Юг»'
    },
    {
        'id': 'ООО «АП-инвест»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «АП-инвест»'
    },
    {
        'id': 'ООО «Арена-Аквапарк»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Арена-Аквапарк»'
    },
    {
        'id': 'ООО «Арктик палладий»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Арктик палладий»'
    },
    {
        'id': 'ООО «Арктик-энерго»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Арктик-энерго»'
    },
    {
        'id': 'ООО «Аэропорт «Норильск»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Аэропорт «Норильск»'
    },
    {
        'id': 'ООО «Бугдаинский рудник»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Бугдаинский рудник»'
    },
    {
        'id': 'ООО «Быстринская сервисная компания»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Быстринская сервисная компания»'
    },
    {
        'id': 'ООО «БЭСК»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «БЭСК»'
    },
    {
        'id': 'ООО «Востокгеология»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Востокгеология»'
    },
    {
        'id': 'ООО «ГРК «Быстринское»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «ГРК «Быстринское»'
    },
    {
        'id': 'ОО «Единство»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Единство»'
    },
    {
        'id': 'ООО «ЗСК»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «ЗСК»'
    },
    {
        'id': 'ООО «Институт Гипроникель»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Институт Гипроникель»'
    },
    {
        'id': 'ООО «Интергеопроект»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Интергеопроект»'
    },
    {
        'id': 'ООО «Интерпромлизинг»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Интерпромлизинг»'
    },
    {
        'id': 'ООО «Колабыт»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Колабыт»'
    },
    {
        'id': 'ООО «КЦЗ «Норникель»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «КЦЗ «Норникель»'
    },
    {
        'id': 'ООО «Масловское»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Масловское»'
    },
    {
        'id': 'ООО «Медвежий ручей»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Медвежий ручей»'
    },
    {
        'id': 'ООО «МФК «Норильский никель»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «МФК «Норильский никель»'
    },
    {
        'id': 'ООО «Норильскгеология»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норильскгеология»'
    },
    {
        'id': 'ООО «Норильский обеспечивающий комплекс»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норильский обеспечивающий комплекс»'
    },
    {
        'id': 'ООО «Норильскпромтранспорт»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норильскпромтранспорт»'
    },
    {
        'id': 'ООО «Норильскникельремонт»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норильскникельремонт»'
    },
    {
        'id': 'ООО «НН-Сервис»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «НН-Сервис»'
    },
    {
        'id': 'ООО «Норильск-ТАВС»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норильск-ТАВС»'
    },
    {
        'id': 'ООО «НТПО»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «НТПО»'
    },
    {
        'id': 'ООО «Норникель-ЕРП»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Норникель-ЕРП»'
    },
    {
        'id': 'ООО «Нортранс – Норильск»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Нортранс – Норильск»'
    },
    {
        'id': 'ООО «Подмосковная Слобода»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Подмосковная Слобода»'
    },
    {
        'id': 'ООО «Порт Лиинахамари»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Порт Лиинахамари»'
    },
    {
        'id': 'ООО «Профессиональный баскетбольный клуб «ЦСКА»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Профессиональный баскетбольный клуб «ЦСКА»'
    },
    {
        'id': 'ООО «Ренонс»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Ренонс»'
    },
    {
        'id': 'ООО «С Джи М»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «С Джи М»'
    },
    {
        'id': 'ООО «Санаторий «Заполярье»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Санаторий «Заполярье»'
    },
    {
        'id': 'ООО «Твой дом»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Твой дом»'
    },
    {
        'id': 'ООО «Ширинское»',
        'selected': false,
        'disabled': false,
        'text': 'ООО «Ширинское»'
    },
    {
        'id': 'ОАО «Россо»',
        'selected': false,
        'disabled': false,
        'text': 'ОАО «Россо»'
    },
    {
        'id': 'ЧОУ ДПО «КЦРП»',
        'selected': false,
        'disabled': false,
        'text': 'ЧОУ ДПО «КЦРП»'
    },
    {
        'id': 'ЧОУ ДПО «Корпоративный университет «Норильский никель',
        'selected': false,
        'disabled': false,
        'text': 'ЧОУ ДПО «Корпоративный университет «Норильский никель'
    }
]

export const text = {
    name: localize({
        ru: 'Фамилия Имя Отчество*',
        en: 'Full name*'
    }),
    company: localize({
        ru: 'Компания*',
        en: 'Company*'
    }),
    phone: localize({
        ru: 'Телефон*',
        en: 'Phone*'
    }),
    phoneAdd: localize({
        ru: 'доб.',
        en: 'ext.'
    }),
    email: localize({
        ru: 'E-mail*',
        en: 'E-mail*'
    }),
    message: localize({
        ru: 'Ваш вопрос*',
        en: 'Message*'
    }),
    button: localize({
        ru: 'Отправить',
        en: 'Send'
    }),
    notificationRequiredTextError: localize({
        ru: 'Заполните все обязательные поля',
        en: 'Fill in all required fields'
    }),
    notificationError: localize({
        ru: 'Ошибка отправки',
        en: 'Send error'
    }),
    notificationValidateError: localize({
        ru: 'Неправильный формат полей',
        en: 'Wrong field format'
    }),
    notificationSuccess: localize({
        ru: 'Отправка успешна',
        en: 'Sending successful'
    }),
    notificationCaptchaError: localize({
        ru: 'Неверный код капчи. Попробуйте снова.',
        en: 'Invalid captcha code. Try again.'
    }),
    notificationCaptchaEmpty: localize({
        ru: 'Введите код с картинки*',
        en: 'Enter the code from the image*'
    }),
    policy: localize({
        ru: 'В соответствии с Федеральным законом «О персональных данных» от 27.07.2006 N 152-ФЗ свободно, своей волей и в своем интересе я даю свое согласие ПАО «ГМК Норильский никель» на обработку моих персональных данных, указанных при заполнении формы обратной связи на сайте www.nornickel.ru',
        en: 'In accordance with the Federal Law of 27.07.2006 No. 152-FL by my free consent, at my own will, and in my interest I agree to the processing of my personal data, which is mentioned in feedback form on website www.nornickel.com, by PJSC “MMC “Norilsk Nickel”'
    }),
    innKpp: localize({
        ru: 'ИНН/КПП*',
        en: 'ИНН/КПП*'
    }),
    operator: localize({
        ru: 'Оператор ЭДО*',
        en: 'Оператор ЭДО*'
    }),
    enterprises: localize({
        ru: 'Предприятие группы «Норникель»*',
        en: 'Предприятие группы «Норникель»*'
    })
}
