<template>
    <div class="buisness-model">
        <div class="container">
            <anchors class="u-bottom-margin--2xl"></anchors>
        </div>
        <section class="buisness-model__front u-bottom-padding--3xl u-top-padding--3xl u-bg-normalize" id="1">
            <div class="container">
                <z-caption class="u-bottom-margin--m u-bold" tag="h2" size="3xl" decor-right>
                    {{text['title-first']}}
                </z-caption>

                <div class="row">
                    <div class="keynumbers col-default-5 col-tablet-12">
                        <div class="keynumbers__paragrph u-bottom-margin--m">
                            <z-caption class="u-bottom-margin--s u-top-margin--s u-bold" uppercase tag="h4" size="l" decor-right decor-left>
                                {{text['subtitle-first']}}
                            </z-caption>
                            <z-key-indicator
                                :value="1257 | numberFilter"
                                :value-text="text['mln-t-of-ore']"
                                class="u-bottom-margin--s"
                            ></z-key-indicator>
                            <div class="dashes u-bottom-margin--2xl">
                                <dash>
                                    <template v-slot:value>Ni</template>
                                    <template v-slot:text>{{9 | numberFilter}} {{text['mln-t']}}</template>
                                </dash>
                                <dash>
                                    <template v-slot:value>Cu</template>
                                    <template v-slot:text>{{16 | numberFilter}} {{text['mln-t']}}</template>
                                </dash>
                                <dash>
                                    <template v-slot:value>{{text['pgms']}}</template>
                                    <template v-slot:text>175 {{text.moz}}</template>
                                </dash>
                            </div>
                        </div>
                        <div class="keynumbers__paragrph u-bottom-margin--m">
                            <z-caption class="u-bottom-margin--s u-bold" uppercase tag="h5" size="l" decor-right decor-left>
                                {{text['subtitle-second']}}
                            </z-caption>
                            <z-key-indicator
                                value="201"
                                :value-text="text['mln-t-of-ore']"
                                class="u-bottom-margin--s"
                            ></z-key-indicator>
                            <div class="dashes u-bottom-margin--2xl">
                                <dash>
                                    <template v-slot:value>Ni</template>
                                    <template v-slot:text>{{13.5 | numberFilter}} {{text['mln-t']}}</template>
                                </dash>
                                <dash>
                                    <template v-slot:value>Cu</template>
                                    <template v-slot:text>{{22.0 | numberFilter}} {{text['mln-t']}}</template>
                                </dash>
                                <dash>
                                    <template v-slot:value>{{text['pgms']}}</template>
                                    <template v-slot:text>246 {{text.moz}}</template>
                                </dash>
                            </div>
                        </div>
                        <div class="keynumbers__paragrph u-bottom-margin--m">
                            <z-caption class="u-bottom-margin--s u-bold" uppercase tag="h5" size="l" decor-right decor-left>
                                {{text['subtitle-third']}}
                            </z-caption>
                            <z-key-indicator
                                value=">70"
                                :value-text="text['years']"
                            ></z-key-indicator>
                        </div>
                    </div>

                    <div class="col-default-6 col-default-offset-1 col-tablet-12 col-tablet-offset-0">
                        <div class="map__text u-bold text-size-xl u-bottom-margin--3xs">{{text['front-text']}}</div>

                        <img v-if="lang === 'ru'" class="u-entire-width" src="/images/business/business-model/front-map.svg" alt="geology">
                        <img v-if="lang === 'en'" class="u-entire-width" src="/images/business/business-model/front-map-eng.svg" alt="geology">
                    </div>
                </div>

            </div>
        </section>

        <section class="buisness-model__mining gray-gradient-bg u-bottom-padding--2xl u-top-padding--2xl" id="2">
            <div class="container">
                <z-caption class="u-bottom-margin--l" tag="h2" size="2xl" decor-right>
                    {{text['title-second']}}
                </z-caption>
                <div class="scrollable u-bottom-margin--m">
                    <productionImg v-if="lang === 'en'" class="u-entire-width min-width-v-tablet"></productionImg>
                    <productionImgRu v-if="lang === 'ru'" class="u-entire-width min-width-v-tablet"></productionImgRu>
                </div>
                <z-link :href="lang === 'ru' ? 'https://ar2020.nornickel.ru/overview/production-scheme' : 'https://ar2020.nornickel.com/overview/production-scheme'" size="l" class="u-flex">
                    <template v-slot:left>
                        <z-icon
                        name="round-arrow"
                        dir="right"
                        width="24"
                        height="24"
                        />
                    </template>
                    <p>
                        <vue-raw :raw="text['link']"/>
                    </p>
                </z-link>
            </div>
        </section>

        <section class="buisness-model__manufacture u-bottom-padding--2xl u-top-padding--2xl" id="3">
            <div class="container">
                <z-caption class="u-bottom-margin--l" tag="h2" size="2xl" decor-right href="/business/transport-and-logistic/">
                    {{text['title-third']}}
                </z-caption>
                <div class="scrollable">
                    <img v-if="lang === 'ru'" class="u-entire-width min-width-v-tablet" src="/images/business/business-model/logistick.svg" alt="logistick">
                    <img v-if="lang === 'en'" class="u-entire-width min-width-v-tablet" src="/images/business/business-model/logistick-en.svg" alt="logistick">
                </div>
            </div>
        </section>

        <section class="buisness-model__finencial u-bottom-padding--xl" id="4">
            <div class="container">
                <div class="row">
                    <div class="col-default-12 col-tablet-12">
                        <z-caption
                            class="u-bottom-margin--s"
                            tag="h2"
                            size="2xl"
                            decor-right
                            href="/investors/disclosure/financials/"
                        >
                        {{text['title-fourth']}}
                        </z-caption>
                        <!-- <p class="sub-title u-bottom-margin--2xl">
                            {{text['revenue-from-sales']}}
                        </p>
                        <img v-if="lang === 'ru'" class="u-entire-width" src="/images/business/business-model/metalls.svg" alt="metalls">
                        <img v-if="lang === 'en'" class="u-entire-width" src="/images/business/business-model/metalls-en.svg" alt="metalls">
                        <p class="sub-title u-top-margin--2xl">
                            {{text['footnote']}}
                        </p> -->
                    </div>
                    <div class="col-default-5 col-tablet-12">
                        <div class="blue-background">
                            <div class="row">
                                <div class="col-default-6 col-desktop-12" >
                                    <z-key-indicator
                                        theme="white"
                                        :value="6.9 | numberFilter"
                                        :value-text="text['net-profit']"
                                    >
                                    </z-key-indicator>
                                </div>
                                <!-- <div class="col-default-6 col-desktop-12">
                                    <z-key-indicator
                                        theme="white"
                                        :value="10.5 | numberFilter"
                                        :value-text="text['EBITDA']"
                                    >
                                    </z-key-indicator>
                                </div>
                                <div class="col-default-6 col-desktop-12">
                                    <z-key-indicator
                                        theme="white"
                                        :value="text['halfX']"
                                        :value-text="text['debt-EBITDA']"
                                    >
                                    </z-key-indicator>
                                </div> -->
                                <div class="col-default-6 col-desktop-12">
                                    <z-key-indicator
                                        theme="white"
                                        value="48%"
                                        :value-text="text['EBITDA-margin']"
                                    >
                                    </z-key-indicator>
                                </div>
                            </div>
                            <z-key-indicator
                                size="l"
                                theme="white"
                                :value="14.4 | numberFilter"
                                :value-text="text['revenue']"
                            >
                            </z-key-indicator>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'
import anchors from './components/anchors.vue'
import productionImg from './img/prodaction-flow.vue'
import productionImgRu from './img/prodaction-flow-ru.vue'

export default {
    name: 'buisness-model',
    mixins: [mixinDevice],
    components: {
        anchors, productionImgRu, productionImg
    },
    data () {
        return {
            data: null,
            isLoading: false,
            currentReports: null,
            text: {
                'title-first': localize({
                    ru: '01. Минерально-сырьевая база и геологоразведка',
                    en: '01. Mineral resource base and exploration'
                }),
                'title-second': localize({
                    ru: '02. Добыча и производство',
                    en: '02. Production flow'
                }),
                'title-third': localize({
                    ru: '03. Логистика и сбыт',
                    en: '03. Logistics and sales '
                }),
                'title-fourth': localize({
                    ru: '04. Финансовые результаты',
                    en: '04. Financial performance '
                }),
                'front-text': localize({
                    ru: 'Уникальная минерально-сырьевая база «Норникеля» формируется за счет активного развития первоклассных активов в России. Постоянное расширение ресурсной базы – гарантия устойчивого долгосрочного развития компании. Геологоразведка обеспечивает поддержание объемов и оптимальной структуры запасов полезных ископаемых.',
                    en: 'Nornickel boasts a unique mineral resource base of Tier 1 assets in Russia. Nornickel’s continued focus on expanding its resource base is essential to its long-term development. Exploration ensures the stable volume and optimal structure of mineral reserves. '
                }),
                'subtitle-first': localize({
                    ru: 'Доказанные и вероятные запасы',
                    en: 'Proven and probable reserves'
                }),
                'subtitle-second': localize({
                    ru: 'Оцененные и выявленные ресурсы',
                    en: 'measured and indicated resources'
                }),
                'subtitle-third': localize({
                    ru: 'обеспеченность ресурсами при текущем уровне добычи',
                    en: 'of resources at the current production rate'
                }),
                'mln-t-of-ore': localize({
                    ru: 'млн т руды',
                    en: 'mln t of ore'
                }),
                'years': localize({
                    ru: 'лет',
                    en: 'years'
                }),
                'moz': localize({
                    ru: 'млн тр. ун.',
                    en: 'moz'
                }),
                'mln-t': localize({
                    ru: 'млн т',
                    en: 'mln t'
                }),
                'pgms': localize({
                    ru: '6 МПГ',
                    en: '6 PGMs'
                }),
                'revenue': localize({
                    ru: 'млрд US$<br>выручка',
                    en: 'USD bn<br>revenue'
                }),
                'revenue-from-sales': localize({
                    ru: 'Структура выручки от реализации металлов в млрд долл. США',
                    en: 'Revenue from sales of metals, USD bn'
                }),
                'EBITDA': localize({
                    ru: 'млрд US$<br>показатель EBITDA',
                    en: 'USD bn<br>EBITDA '
                }),
                'EBITDA-margin': localize({
                    ru: 'рентабельность<br>EBITDA',
                    en: 'EBITDA<br/>margin'
                }),
                'debt-EBITDA': localize({
                    ru: 'чистый долг<br>EBITDA',
                    en: 'net debt<br/>EBITDA'
                }),
                'net-profit': localize({
                    ru: 'млрд US$ EBITDA',
                    en: 'USD bn<br>EBITDA'
                }),
                'link': localize({
                    ru: 'Полная схема производства в Годовом отчете 2020',
                    en: 'Full production flow chart is available in the Annual Report 2020'
                }),
                'footnote': localize({
                    ru: '* По итогам 2023 г.',
                    en: '* Based on 2023 results'
                }),
                'halfX': localize({
                    ru: '1,2x',
                    en: '1.2x'
                })
            }
        }
    },
    computed: {
        lang () {
            return this.$root.app.page.lang
        }
    }
}
</script>

<style lang="scss">
.buisness-model {
    &__front {
        background-image: url(/images/business/business-model/front-bg.png);
    }

    .keynumbers__paragrph {
        .z-key-indicator__container {
            margin-bottom: 0 !important;
        }

        .z-key-indicator__value-text {
            font-style: 30px !important;
        }
    }

    .dashes {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: $token-spacers-m;
        column-gap: $token-spacers-2-xs;
        margin-bottom: $token-spacers-m * 2;
    }

    &__finencial {
        .blue-background {
            background-color: $token-colors-navy-blue;
            border-radius: 40px 0 0 40px;
            padding: 80px 64px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .z-key-indicator__value {
                @include margin-level(right, XS);
            }

            @include breakpoint (mobile) {
                padding: 40px 32px;
                min-height: initial;
            }
        }
    }

    .keynumbers  {
        .z-key-indicator__value-text {
            font-size: 30px;
        }
    }

    .blue-background {
        .z-key-indicator__value {
            margin-right: $token-spacers-2-xs;
        }
    }

    .z-key-indicator {
        &__value-text {
            line-height: 140%;
        }

        &__value-wrapper {
            flex-wrap: nowrap;
        }
    }

    .keynumbers {
        .z-key-indicator__value-text {
            font-size: 30px;
        }

        .z-key-indicator__container {
            margin-bottom: 0;
        }
    }

}

</style>
