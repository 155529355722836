<template>
    <section class="mp-development">
        <div class="container">
            <div class="row">
                <div class="col-default-5 col-desktop-12">
                    <div class="mp-development__esg">
                        <z-caption
                            tag="h2"
                            size="3xl"
                            decor-left
                            decor-right
                            class="main-page__title u-bottom-margin--s-important"
                            weight="600"
                        >
                            <span data-scroll-heading v-html="text.developmentSection.title"></span>
                        </z-caption>
                        <p
                            data-scroll-translate
                            class="mp-development__esg-text"
                            v-html="text.developmentSection.text"></p>
                        <div class="mp-development__esg-link" data-scroll-translate>
                            <z-button
                                kind="primary"
                                size="m"
                                class="mp-development__esg-button u-normal"
                                href="/sustainability/"
                                tag="a">
                                ESG
                                <template v-slot:right>
                                    <z-icon
                                        name="arrow-long"
                                        dir="right"
                                        width="18"
                                        height="18"></z-icon>
                                </template>
                                <template v-slot:append>
                                    <span v-html="text.developmentSection.more"></span>
                                </template>
                            </z-button>
                        </div>
                    </div>
                </div>

                <template>
                    <div
                        class="mp-development__bio-container col-default-3 col-desktop-6 col-v-tablet-12">
                        <div class="mp-development__bio-info">
                            <z-caption
                                tag="h2"
                                size="m"
                                decor-left
                                decor-right
                                weight="700"
                                uppercase
                                class="main-page__title u-top-margin--3xs u-bottom-margin--xs-important">
                                <span data-scroll-heading v-html="text.biodiversity.title"></span>
                            </z-caption>
                            <p
                                data-scroll-translate
                                class="gray-60-text u-bottom-margin--xs"
                                v-html="text.biodiversity.text"></p>
                            <z-button
                                data-scroll-translate
                                kind="secondary"
                                class="mp-development__button"
                                :href="
                                    lang === 'ru'
                                        ? 'https://life.nornickel.ru/'
                                        : 'https://life.nornickel.com/'
                                "
                                target="_blank"
                                tag="a">
                                <span
                                    class="u-bold"
                                    v-html="text.biodiversity.link"></span>
                                <template v-slot:right>
                                    <z-icon
                                        name="external"
                                        width="24"
                                        height="24"></z-icon>
                                </template>
                            </z-button>
                        </div>
                    </div>
                    <a class="mp-development__eco-container col-desktop-5 col-v-tablet-12" href="/sustainability/environment/biodiversity/" data-scroll-translate>
                        <img
                            class="mp-development__eco-img u-entire-width"
                            src="/images/biodiversity/save-ecosystem.jpg"
                            alt="" />
                        <div class="mp-development__eco-content">
                            <z-logo
                                inverted
                                isIndex
                                siteDir="" />
                            <p
                                class="mp-development__eco-title"
                                v-html="text.biodiversity.save"></p>
                        </div>
                    </a>
                </template>
            </div>

            <div class="mp-development__bio-elements" data-scroll-translate>
                <a
                    v-for="(element, index) in elements"
                    :key="index"
                    :href="element.link"
                    class="bio-item">
                    <div class="bio-item__img-container">
                        <img
                            class="bio-item__img"
                            :src="element.img"
                            alt="" />
                        <div class="bio-item__icon-container">
                            <z-icon
                                name="arrow-long"
                                width="24"
                                height="24"
                                dir="right" />
                        </div>
                    </div>
                    <p
                        class="bio-item__title"
                        v-html="element.title"></p>
                    <div class="bio-item__content">
                        <p
                            class="bio-item__title-small"
                            v-html="element.title"></p>
                        <p
                            class="bio-item__text"
                            v-html="element.text"></p>
                    </div>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import { localize } from '@/utils/i18n'
export default {
    name: 'section-development',
    data () {
        return {
            lang: this.$root.lang,
            elements: [
                {
                    title: localize({
                        ru: 'Вода',
                        en: 'Water'
                    }),
                    text: localize({
                        ru: 'Важнейшими аспектами деятельности «Норникеля» являются ответственное и&nbsp;рациональное использование водных ресурсов',
                        en: 'Norilsk Nickel&rsquo;s main priority is&nbsp;to&nbsp;use water sustainably and reduce water pollution'
                    }),
                    img: '/images/biodiversity/water.jpg',
                    link: '/sustainability/environment/water/'
                },
                {
                    title: localize({
                        ru: 'Земля',
                        en: 'Land'
                    }),
                    text: localize({
                        ru: '«Норникель» осуществляет поступательную рекультивацию земель',
                        en: 'Norilsk Nickel focuses on&nbsp;the rehabilitation of&nbsp;all land affected'
                    }),
                    img: '/images/biodiversity/earth.jpg',
                    link: '/sustainability/environment/land/'
                },
                {
                    title: localize({
                        ru: 'Воздух',
                        en: 'Air'
                    }),
                    text: localize({
                        ru: '&laquo;Норникель&raquo; ставит перед собой задачу выйти на&nbsp;ведущие позиции в&nbsp;отрасли по&nbsp;темпам сокращения выбросов в&nbsp;атмосферу',
                        en: 'Norilsk Nickel&rsquo;s goal is&nbsp;to&nbsp;achieve industry-leading emissions performance'
                    }),
                    img: '/images/biodiversity/air.jpg',
                    link: '/sustainability/environment/air/'
                }
            ],
            text: {
                developmentSection: {
                    title: localize({
                        ru: 'Устойчивое развитие',
                        en: 'Sustainability'
                    }),
                    text: localize({
                        ru: 'Программы защиты естественной среды обитания и&nbsp;поддержка традиционных видов деятельности',
                        en: 'Programmes to&nbsp;protect natural habitats and support traditional activities'
                    }),
                    more: localize({
                        ru: 'Подробнее об&nbsp;устойчивом развитии',
                        en: 'Learn more'
                    })
                },
                biodiversity: {
                    title: localize({
                        ru: 'Биоразнообразие',
                        en: 'Biodiversity'
                    }),
                    text: localize({
                        ru: '&laquo;Норникель&raquo; представляет уникальный веб-проект, посвящённый биоразнообразию Кольского полуострова, Таймыра и&nbsp;Забайкальского края.',
                        en: '“Nornickel” presents a&nbsp;unique web project dedicated to&nbsp;the biodiversity of&nbsp;the Kola Peninsula, Taymyr and Trans-Baikal Territory.'
                    }),
                    link: localize({
                        ru: 'Смотреть на сайте',
                        en: 'Go to&nbsp;website'
                    }),
                    save: localize({
                        ru: 'Сохраняя экосистемы',
                        en: 'Saving ecosystems'
                    })
                }
            }
        }
    }
}
</script>

<style lang="scss">
$custom-transition: 0.6s ease-in-out;
$bio-transition: 0.6s ease-in-out;

.mp-development {
    padding-top: 130px;
    padding-bottom: 100px;

    @include breakpoint(laptop) {
        @include padding-level(top, 3XL);
        @include padding-level(bottom, 2XL);
    }

    &__container {
        margin-bottom: $token-spacers-2-xl;

        @media screen and(max-width: 1760px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &__esg {
        @media screen and(max-width: 1760px) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    &__esg-text {
        font-weight: 400;
        font-size: 26px;
        line-height: 120%;
        letter-spacing: -0.005em;
        color: $token-colors-gray-60;
        @include margin-level(bottom, XL);

        @include breakpoint(tablet) {
            @include typo-level(M);
        }

        &--tel {
            font-size: 24px;
        }
    }

    &__esg-link {
        display: flex !important;
        text-decoration: none;
        font-size: $token-spacers-2-xs;
        line-height: 110%;
        color: $token-colors-blue;
        font-weight: 600;

        &:hover {
            .z-button {
                background-color: $token-colors-navy-blue;
            }

            .z-link {
                color: $token-colors-navy-blue;
            }
        }

        .z-link {
            font-size: $token-spacers-2-xs;
            line-height: 120%;
            font-weight: 600;
            padding-left: 20px;
            margin-left: -20px;
        }

        path {
            fill: $token-colors-white !important;
        }

        @media screen and(max-width: 1760px) {
            margin-bottom: 36px;
        }
    }

    &__esg-button {
        margin-right: $token-spacers-3-xs;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
    }

    &__bio-info {
        .u-flex--row {
            flex-wrap: nowrap;
        }

        .z-list .z-list-item {
            padding-left: 24px;

            &:before {
                left: 0px;
            }
        }

        .z-link {
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
        }
    }

    &__eco-container {
        width: 28%;
        margin-left: auto;
        position: relative;
        border-radius: 0px 40px 0px 20px;
        overflow: hidden;

        @include breakpoint(laptop) {
            margin-top: 0;
            margin-left: auto;
        }

        .z-logo {
            width: 95px;
            height: 20px;

            @include margin-level(bottom, 2XS);
        }

        &:hover {
            .mp-development__eco-img {
                transform: scale(1.5);
            }
            .mp-development__eco-content {
                transform: translateX(13px);
            }
        }
    }

    &__eco-img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        transition: transform $custom-transition;
        user-select: none;
    }

    &__eco-content {
        position: absolute;
        left: 47px;
        bottom: 29px;
        max-width: 35%;
        transition: transform $custom-transition;
    }

    &__eco-title {
        color: $token-colors-white;
        @include typo-level(3XL);
        line-height: 0.9;
    }

    &__bio-container {
        @include margin-level(left, XL);
        @include breakpoint(laptop) {
            margin-left: 0;
        }
    }

    &__bio-elements {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-top: 100px;
        margin-top: 0 !important;
        width: 100%;
        gap: 24px;

        @include breakpoint(laptop) {
            @include padding-level(top, 2XL);
        }

        @include breakpoint(v-tablet) {
            flex-direction: column;
        }

        @include breakpoint(mobile) {
            gap: 32px;
        }

        .bio-item {
            flex: 1;
        }
    }
}

.bio-item {
    max-width: 100%;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    color: $token-colors-gray-60;
    position: relative;
    transition: margin $bio-transition 0.3s;
    $parent: &;

    @include breakpoint(tablet) {
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
    }

    @include breakpoint(v-tablet) {
        flex-direction: row;
        gap: 0;
    }

    @include breakpoint(mobile) {
        flex-direction: column;
        gap: 24px;
    }

    &:hover {
        // margin-bottom: -56px;
        transition: margin $bio-transition;

        @include breakpoint(tablet) {
            margin-bottom: 0;
        }

        #{$parent}__title {
            opacity: 0;
            // transition: opacity $bio-transition;
        }

        #{$parent}__content {
            opacity: 1;
            // transition: opacity $bio-transition 0.6s;
        }

        #{$parent}__img-container {
            transform: scale(1.01);
            // width: 186px;
            // height: 186px;
            // transition: width $bio-transition 0.3s, height $bio-transition 0.3s;

            // @include breakpoint(laptop) {
            //     width: 166px;
            //     height: 166px;
            // }

            // @include breakpoint(tablet) {
            //     width: 146px;
            //     height: 146px;
            // }
        }

        #{$parent}__icon-container {
            background-color: $token-colors-navy-blue;
            width: 54px;
            height: 54px;
            top: 13px;
            transform: translate(0);

            @include breakpoint(laptop) {
                width: 34px;
                height: 34px;
                top: 13px;
            }
        }
    }

    &__img-container {
        width: 160px;
        height: 160px;
        @include margin-level(right, M);
        position: relative;
        flex-shrink: 0;
        transition: width $bio-transition 0.3s, height $bio-transition 0.3s;

        @include breakpoint(tablet) {
            width: 146px;
            height: 146px;
        }
    }

    &__img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
    }

    &__icon-container {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
        width: 36px;
        height: 36px;
        border: 1px solid $token-colors-white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $token-colors-white;
        background-color: $token-colors-blue;
        transition: all $bio-transition;
        will-change: transform, top;

        .z-icon path {
            fill: $token-colors-white;
        }

        @include breakpoint(tablet) {
            width: 34px;
            height: 34px;
            top: 13px;
            transform: translate(0);
        }
    }

    &__title {
        opacity: 1;
        font-size: 85px;
        font-weight: 250;
        line-height: 120%;
        transition: opacity 0.4s ease-in-out;
        margin-bottom: 0;
        align-self: center;

        @include breakpoint(laptop) {
            font-size: 60px;
        }

        @include breakpoint(tablet) {
            display: none;
        }
    }

    &__title-small {
        color: $token-colors-blue;
        @include typo-level(3XL);
        font-weight: 400;
        line-height: 100%;
        @include margin-level(bottom, XS);
        background-repeat: no-repeat;
        background-image: linear-gradient(currentColor, currentColor);
        background-size: 0% 1px;
        background-position: 0% 100%;
        width: fit-content;
        transition: background-size $bio-transition;

        &:hover {
            background-size: 100% 1px;
            transition: background-size $bio-transition;
        }
    }

    &__content {
        position: absolute;
        left: 230px;
        opacity: 0;
        transition: opacity $bio-transition;

        @include breakpoint(laptop) {
            left: 190px;
        }

        @include breakpoint(tablet) {
            opacity: 1;
            position: static;
        }
    }

    &__text {
        @include typo-level(S);
        font-weight: 400;
        line-height: 130%;
    }
}
</style>
