/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ical': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.284 5.72L2 5.945l2.976 17.024L22 19.993 19.024 2.969l-1.279.223 2.768 15.837-14.46 2.528L3.282 5.72zm8.957 4.63l.158.893 3.707-.641c-.38.613-.706 1.347-.985 2.202-.288.845-.464 1.71-.539 2.592-.074.883-.065 1.616.028 2.211l.948-.167a9.684 9.684 0 01-.01-2.137c.094-.948.307-1.877.632-2.806.326-.93.679-1.691 1.069-2.286l-.13-.715-4.878.855zm-2.064 8.027l.93-.158-1.32-7.563-.595.102c-.111.353-.325.734-.65 1.153a5.56 5.56 0 01-1.198 1.124l.157.892c.27-.158.567-.372.892-.65.326-.28.576-.54.762-.79l1.022 5.89z" _fill="#0077C8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M1.818 4.743l17-2.972.943 5.4-16.999 2.972-.944-5.4zM12.975 7.19l-.511-2.936.39-.065.455 2.592 1.45-.25.065.343-1.85.316zm-1.143-2.825l-.39.065.297 1.69c.065.372.047.632-.056.781-.102.149-.288.251-.576.297a.853.853 0 01-.436-.027.5.5 0 01-.298-.233c-.065-.111-.12-.297-.167-.548L9.91 4.7l-.39.064.297 1.7c.056.307.14.549.241.716.103.167.251.279.447.344.185.065.418.074.696.027.288-.046.502-.139.66-.278a.85.85 0 00.288-.483 1.935 1.935 0 00-.019-.725l-.297-1.7zM7.976 7.153l-.344.112c.047.297.149.501.307.631.167.13.371.168.632.121a.933.933 0 00.436-.186.665.665 0 00.232-.362c.038-.14.038-.344-.009-.585l-.353-1.998-.39.065.353 2.026c.037.167.037.288.028.371a.323.323 0 01-.112.205.487.487 0 01-.241.111c-.13.019-.242 0-.335-.074-.083-.065-.158-.214-.204-.437z" _fill="#0077C8"/>'
  }
})
