<template>
    <div class="container">
        <div class="mp-contacts">
            <div class="row">
                <div class="col-default-8 col-tablet-12">
                    <z-caption
                        :href="device === 'mobile' ? false : '/contacts/'"
                        :tag="device === 'mobile' ? 'h2' : 'a'"
                        size="3xl"
                        decor-left
                        decor-right
                        class="main-page__title u-bottom-margin--m-important"
                    >
                        <span data-scroll-heading>
                            {{ contacts.title }}
                        </span>
                    </z-caption>

                    <div class="mp-contacts__addresses u-flex--row" data-scroll-translate>
                        <div class="mp-contacts__map-container">
                            <div class="mp-contacts__map-wrapper">
                                <div
                                    id="map"
                                    class="mp-contacts__map">
                                    <yandex-map
                                        :center="[55.750484, 37.539527]"
                                        :coords="[55.750484, 37.539527]"
                                        :icon-title="iconContent"
                                        icon-image-href="/images/main-page/map-pin.png"></yandex-map>
                                </div>
                            </div>
                        </div>
                        <div class="mp-contacts__values">
                            <div class="u-bottom-margin--l">
                                <h2 class="mp-contacts__subtitle">
                                    {{ contacts.subtitle }}
                                </h2>
                                <p class="mp-contacts__text">
                                    {{ contacts.address.title }}
                                </p>
                                <p class="mp-contacts__text">
                                    {{ contacts.address.text }}
                                </p>
                            </div>

                            <div class="main-page__flex-container">
                                <div class="mp-contacts__item">
                                    <p
                                        class="u-bottom-margin--2xs text-size-s gray-50-text u-line-height_1-2">
                                        {{ contacts.tel }}
                                    </p>
                                    <z-link
                                        href="tel: +7(495)7877667"
                                        size="xl">
                                        <span>+7 (495) 787 76 67</span>
                                    </z-link>
                                </div>
                                <div class="mp-contacts__item">
                                    <p
                                        class="u-bottom-margin--2xs text-size-s gray-50-text u-line-height_1-2">
                                        {{ contacts.email }}
                                    </p>
                                    <z-link
                                        href="mailto: gmk@nornik.ru"
                                        size="xl">
                                        <span>gmk@nornik.ru</span>
                                    </z-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mp-contacts__assets col-default-4 col-tablet-12 u-left-padding--2xl">
                    <z-caption
                        tag="h2"
                        size="xl"
                        decor-left
                        decor-right
                        class="main-page__title"
                        uppercase
                    >
                        <span data-scroll-heading>
                            {{ assetsSection.title }}
                        </span>
                    </z-caption>

                    <listing
                        data-scroll-translate
                        :items="[
                            {
                                logo: '/images/main-page/RU.jpg',
                                text: assets.norilsk,
                                link: '/business/assets/norilsk-division/'
                            },
                            {
                                logo: '/images/main-page/RU.jpg',
                                text: assets.kola,
                                link: '/business/assets/kola-division-russia/'
                            },
                            {
                                logo: '/images/main-page/RU.jpg',
                                text: assets.zabaykalsky,
                                link: '/business/assets/zabaykalsky-division/'
                            },
                            {
                                logo: '/images/main-page/FI.jpg',
                                text: assets.finland,
                                link: '/business/assets/kola-division-finland/'
                            },
                            {
                                logo: '/images/main-page/light.jpg',
                                text: assets.gas,
                                link: '/business/assets/energy-and-gas-assets/'
                            }
                        ]"
                    ></listing>
                </div>
            </div>
            <div class="u-flex mp-contacts__wrapper" data-scroll-translate>
                <div class="mp-contacts__actions">
                    <z-button
                        size="l"
                        kind="primary"
                        @click="$root.$bus.$emit('open-modal', { id: 'mainPageFormModal' })"
                        class="u-right-margin--m">
                        {{ contacts.buttons.call }}
                        <template v-slot:right>
                            <z-icon
                                name="arrow-long"
                                dir="right"
                                width="18"
                                height="18"></z-icon>
                        </template>
                    </z-button>
                    <z-button
                        size="l"
                        kind="secondary"
                        tag="a"
                        href="/sustainability/corporate-hotline/ ">
                        {{ contacts.buttons.trust }}
                        <template v-slot:right>
                            <z-icon
                                name="arrow-long"
                                dir="right"
                                width="18"
                                height="18"></z-icon>
                        </template>
                    </z-button>
                </div>
                <div class="mp-contacts__links-container">
                    <div class="mp-contacts__socials">
                        <z-link
                            href="https://vk.com/nornickel_official"
                            class="mp-contacts__social">
                            <z-icon
                                name="vk"
                                width="32"
                                height="32"></z-icon>
                        </z-link>
                        <z-link
                            href="https://www.youtube.com/user/NornikOfficial"
                            class="mp-contacts__social">
                            <z-icon
                                name="youtube"
                                width="32"
                                height="32"></z-icon>
                        </z-link>
                        <z-link
                            :href="
                                lang !== 'ru'
                                    ? 'https://www.nornickel.com/rss/'
                                    : 'https://www.nornickel.ru/rss/'
                            "
                            class="mp-contacts__social">
                            <z-icon
                                name="rss"
                                width="32"
                                height="32"></z-icon>
                        </z-link>
                        <z-link
                            href="https://t.me/nornickel_official"
                            class="mp-contacts__social">
                            <z-icon
                                name="telegram"
                                width="32"
                                height="32"></z-icon>
                        </z-link>
                        <z-link
                            href="https://ok.ru/group/55429567348892"
                            class="mp-contacts__social">
                            <z-icon
                                name="Odnoklassniki"
                                width="32"
                                height="32"></z-icon>
                        </z-link>
                    </div>
                    <div
                        class="u-flex mp-contacts__pro"
                        v-if="lang === 'ru'">
                        <z-link
                            href="https://www.tinkoff.ru/invest/social/profile/Nornickel_Official/"
                            class="mp-contacts__pro-link">
                            Пульс
                            <z-icon
                                name="external"
                                width="22"
                                height="22" />
                        </z-link>
                        <z-link
                            href="https://smart-lab.ru/my/Nornickel/"
                            class="mp-contacts__pro-link">
                            Смартлаб
                            <z-icon
                                name="external"
                                width="22"
                                height="22" />
                        </z-link>
                        <z-link
                            href="https://bcs-express.ru/profit/profile/09f756c7-b0d5-4c4c-b0e9-bb6deae36baf"
                            class="mp-contacts__pro-link">
                            Профит
                            <z-icon
                                name="external"
                                width="22"
                                height="22" />
                        </z-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import { localize } from '@/utils/i18n'

export default {
    name: 'section-contacts',
    mixins: [mixinDevice],
    data () {
        return {
            lang: this.$root.lang,
            iconContent: localize({
                ru: '<p style="background: #FFFFFF; display: inline-block; white-space: nowrap; font-weight: 300; font-size: 18px; line-height: 150%; color: #004C97; border-radius: 6px; padding: 8px;">Главный офис</p>',
                en: '<p style="background: #FFFFFF; display: inline-block; white-space: nowrap; font-weight: 300; font-size: 18px; line-height: 150%; color: #004C97; border-radius: 6px; padding: 8px;">Head Office</p>'
            }),
            contacts: {
                title: localize({
                    ru: 'Контакты',
                    en: 'Contacts'
                }),
                subtitle: localize({
                    ru: 'ПАО «ГМК «Норильский никель»',
                    en: 'PJSC “MMC “Norilsk Nickel”'
                }),
                address: {
                    title: localize({
                        ru: 'Адрес:',
                        en: 'Address:'
                    }),
                    text: localize({
                        ru: 'Российская Федерация, 123112, г. Москва, 1-й Красногвардейский проезд., д. 15',
                        en: '15, 1st Krasnogvardeysky Drive, Moscow, 123112, Russia'
                    })
                },
                tel: localize({
                    ru: 'Телефон:',
                    en: 'Phone:'
                }),
                email: 'E-mail:',
                buttons: {
                    call: localize({
                        ru: 'Связаться с нами',
                        en: 'Connect with us'
                    }),
                    trust: localize({
                        ru: 'Служба корпоративного доверия',
                        en: 'Corporate Trust Line'
                    })
                }
            },
            assets: {
                norilsk: localize({
                    ru: 'Норильский дивизион',
                    en: 'Norilsk Division'
                }),
                kola: localize({
                    ru: 'Кольский дивизион (Россия)',
                    en: 'Kola Division (Russia)'
                }),
                zabaykalsky: localize({
                    ru: 'Забайкальский дивизион',
                    en: 'Zabaykalsky Division'
                }),
                finland: localize({
                    ru: 'Кольский дивизион (Финляндия)',
                    en: 'Kola Division (Finland)'
                }),
                gas: localize({
                    ru: 'Энергетические и газовые активы',
                    en: 'Energy and Gas Assets'
                })
            },
            assetsSection: {
                title: localize({
                    ru: 'Активы',
                    en: 'Assets'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.mp-contacts {
    @include breakpoint(v-tablet) {
        margin-right: 0;
    }

    &__map {
        border-radius: 0px 40px 0px 0px;
        overflow: hidden;
    }

    #map {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    &__subtitle {
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        color: $token-colors-black;
        @include margin-level(bottom, XS);
    }

    &__map-wrapper {
        @include margin-level(right, L);
        position: relative;
        padding-top: 55%;
    }

    &__map-container {
        width: calc((100% - 32px) * 0.9);
        max-width: 54%;
        min-width: 50%;

        @include breakpoint(laptop) {
            width: calc((100% - 52px) * 0.9);
            max-width: 100%;
        }
    }

    &__values {
        padding-right: 137px;

        @include breakpoint(laptop) {
            padding-right: 0;
        }
    }

    &__addresses {
        flex-wrap: nowrap;
        align-items: start;

        @include breakpoint(v-tablet) {
            flex-direction: column;

            .mp-contacts__map-container {
                width: 100%;
                margin-bottom: 32px;
            }
        }
    }

    &__text {
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: $token-colors-gray-60;

        &:not(:last-child) {
            @include margin-level(bottom, 2XS);
            max-width: 510px;
        }
    }

    &__actions {
        flex-shrink: 0;
        @include margin-level(right, M);

        @include breakpoint(tablet) {
            width: 100%;
            & > * {
                margin-bottom: 24px;
                margin-right: 24px;
            }
        }
    }

    &__socials {
        display: flex;
        gap: $token-spacers-2-xs;
        @include margin-level(right, M);

        @include breakpoint(v-tablet) {
            margin-right: 0;
        }

        @include breakpoint(mobile) {
            width: 100%;
            justify-content: space-between;
        }
    }

    &__social {
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 54px;
        border: 1px solid $token-colors-blue;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;

        &.z-link {
            transition: background-color $transition, border-color $transition;
        }

        .z-icon path {
            fill: $token-colors-blue;
        }

        @include breakpoint(mobile) {
            margin-right: 0;
        }

        &:hover {
            background-color: $token-colors-navy-blue;

            .z-icon path {
                fill: $token-colors-white !important;
                stroke: $token-colors-white !important;
            }
        }
    }

    &__pro {
        display: flex;

        @include breakpoint(v-tablet) {
            margin-top: $token-spacers-xs;
            width: 100%;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
            gap: 16px;
        }
    }

    &__pro-link {
        flex-shrink: 0;
        height: 54px;

        &.z-link {
            padding: 8px 24px;
            background-color: $token-colors-blue-20;
            border-radius: 40px;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .z-link__text {
            display: inline-flex;
        }

        .z-icon {
            margin-left: 8px;
        }

        &:not(:last-child) {
            @include margin-level(right, 2XS);
        }

        @include breakpoint(mobile) {
            margin-right: 0 !important;
        }

        .z-icon path:nth-child(2) {
            transform-origin: left bottom;
            transition: transform 0.3s ease-out;
        }

        &:hover {
            .z-icon path:nth-child(2) {
                transform: scale(1.1);
            }
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 48px;
        @include margin-level(top, XL, false, true);

        @include breakpoint(tablet) {
            margin-top: 0;
            margin-bottom: $token-spacers-xs;
        }

        @include breakpoint(v-tablet) {
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
        }
    }

    &__assets {
        @include breakpoint(tablet) {
            padding-left: 12px;
        }
    }

    &__links-container {
        display: flex;
        align-items: center;

        @include breakpoint(v-tablet) {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
</style>
