<template>
<div class="media-filter">
    <div class="row">
        <div class="filter-container__header col-default-12 u-collapse--bottom u-bottom-padding--xs">
            <z-caption
                tag="h2"
                decor-right
                size="m"
                weight="700"
                uppercase
                class="filter-container__title"
            >
                <span>{{filter}}</span>
            </z-caption>
            <z-button
                kind="secondary"
                size="s"
                class="filter-container__reset-btn"
                v-if="showResetBtn"
                @click="clear"
            >
                <span>{{reset}}</span>
                <template v-slot:right>
                    <z-icon
                        name="close"
                        width="14"
                        height="14"
                    />
                </template>
            </z-button>
        </div>

        <div class="media-filter__input col-default-3 col-desktop-6 col-v-tablet-12 u-collapse--top">
            <div class="media-filter__header u-entire-width">
                <p>{{period}}</p>
            </div>
            <div
                class="u-flex--row u-flex--no-wrap media-filter__period"
            >
                <z-date-picker
                    :appendToBody="false"
                    :placeholder="enter"
                    v-model="filters.from"
                    class="col-mobile-12"
                ></z-date-picker>
                <span class="u-left-padding--3xs u-right-padding--3xs hide-me--mibile">—</span>
                <z-date-picker
                    :appendToBody="false"
                    :placeholder="enter"
                    v-model="filters.to"
                    class="col-mobile-12"
                ></z-date-picker>
            </div>
        </div>

        <div
            v-if="isExistFilterParameter('subjects')"
            class="media-filter__input col-default-4 col-desktop-6 col-v-tablet-12 u-collapse--top"
        >
            <div class="media-filter__header">
                <p>{{topic}}</p>
            </div>
            <z-select-multi
                :data="subjects"
                v-model="filters.subjects"
                ref="topics"
                name="topics"
                :placeholder="all"
            ></z-select-multi>
        </div>

        <div
            v-if="isExistFilterParameter('types')"
            class="media-filter__input col-default-2 col-desktop-6 col-v-tablet-12 u-collapse--top"
        >
            <div class="media-filter__header">
                <p>{{type}}</p>
            </div>
            <z-select-multi
                :data="types"
                v-model="filters.types"
                ref="type"
                name="type"
                :placeholder="all"
            ></z-select-multi>
        </div>

        <div
            class="media-filter__input col-default-3 col-desktop-6 col-v-tablet-12 u-collapse--top"
        >
            <div class="media-filter__header">
                <p>{{search}}</p>
            </div>
            <z-input
                name="input1"
                :placeholder="keyword"
                ref="search"
                v-model="filters.name"
                :validateOnBlur="false"
                iconName="magnifier"
            ></z-input>
        </div>
    </div>
</div>

</template>

<script>
import { localize } from '@/utils/i18n'
import { debounce } from 'throttle-debounce'

export default {
    name: 'media-filter',
    data () {
        return {
            showResetBtn: false,
            subjects: [],
            types: [],
            filters: {
                from: null,
                to: null,
                subjects: null,
                types: null,
                name: null
            },
            filter: localize({
                ru: 'Фильтр ',
                en: 'Filter'
            }),
            period: localize({
                ru: 'Период',
                en: 'Period'
            }),
            topic: localize({
                ru: 'Темы',
                en: 'Topic'
            }),
            type: localize({
                ru: 'Тип материала',
                en: 'Type'
            }),
            search: localize({
                ru: 'Поиск',
                en: 'Search'
            }),
            reset: localize({
                ru: 'Сбросить фильтр',
                en: 'Reset filter'
            }),
            all: localize({
                ru: 'Все',
                en: 'All'
            }),
            keyword: localize({
                ru: 'Введите ключевое слово',
                en: 'Enter a keyword'
            }),
            enter: localize({
                ru: 'Введите',
                en: 'Enter'
            })
        }
    },
    methods: {
        clear () {
            Object.keys(this.filters).forEach(key => {
                if (key === 'topic' || key === 'type') {
                    this.filters[key] = ''
                } else {
                    this.filters[key] = null
                }
            })

            this.showResetBtn = false
        },
        send: debounce(100, _this => {
            _this.$emit('change', _this.filters)
        }),
        isExistFilterParameter (parameter) {
            return this.app.filters[parameter] &&
            this.app.filters[parameter].length > 0
        }
    },

    computed: {
        lang () {
            return window.App.page.lang
        },
        app () {
            return window.App.components['media-library']
        }
    },

    watch: {
        filters: {
            handler () {
                const status = Object.values(this.filters).reduce((summ, cur) => {
                    if (Array.isArray(cur)) {
                        return cur.length
                    } else if (cur) {
                        return true
                    }

                    return summ
                }, false)

                if (status) {
                    this.showResetBtn = true
                } else {
                    this.showResetBtn = false
                }

                this.send(this)
            },
            deep: true
        }
    },
    mounted () {
        this.types = this.app.filters.types
        this.subjects = this.app.filters.subjects
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
