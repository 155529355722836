<template>
    <nav
        class="footer-menu"
        aria-label="Bottom navigation"
        v-if="data && data.length"
    >
      <ul class="footer-menu__list">
        <li
            class="footer-menu__list-item"
            v-for="(item, index) in data"
            :key="index"
        >
            <a :href="item.link" class="footer-menu__link" :target="isBlank(item)">
                <span v-html="item.name"></span>
            </a>
        </li>
      </ul>
    </nav>
</template>

<script>
export default {
    name: 'navigation-bottom',
    data () {
        return {
            data: this.$root.app.components.navigation.bottom
        }
    },
    methods: {
        isBlank (item) {
            return (item.blank === true) ? '_blank' : null
        }
    }
}

</script>

<style lang="scss">
.footer-menu {
    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include breakpoint (mobile) {
            justify-content: flex-start;
        }
    }

    &__link {
        color: inherit;
        text-decoration: none;
        font-size: 14px;
        letter-spacing: 0.3px;
        white-space: nowrap;

        @include breakpoint(tablet) {
            flex-wrap: wrap;
        }

        @media (max-width: 399px) {
            flex-direction: column;
        }

        &:hover {
            color: $token-colors-blue-30;
        }
    }

    &__list-item {
        margin-right: 10px;

        @include breakpoint(tablet) {
            margin-bottom: 20px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
</style>
