<template>
    <div class="clean-banner">
        <div class="container">
            <div class="clean-banner__subtitle">Наша цель</div>
            <div class="clean-banner__title">Комфортный город</div>
            <div class="clean-banner__description">
                Программа по&nbsp;санитарной очистке и&nbsp;благоустройству промышленных территорий
            </div>
            <z-button @click="scrollTo" size="l">
                Узнайте больше о&nbsp;нашей программе
                <template v-slot:right>
                    <z-icon name="arrow-long" dir="down" width="18" height="18"></z-icon>
                </template>
            </z-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'clean-banner',
    methods: {
        scrollTo () {
            this.$emit('scroll-to')
        }
    }
}
</script>

<style lang="scss">
.clean-banner {
    background-image: url('/images/clean-norilsk/bg-header.jpg');
    background-repeat: no-repeat;
    padding: 266px 0 308px;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;

    @include breakpoint(tablet) {
        padding: 227px 0 160px;
        background-size: cover;
        background-position: left top;
    }

    @include breakpoint(v-tablet) {
        padding: 160px 0 280px;
        background-image: url('/images/clean-norilsk/bg-header-v-tablet.jpg');
        background-position: center bottom;
    }

    @include breakpoint(mobile) {
        padding: 100px 0 280px;
    }

    @media (max-width: 400px) {
        padding: 107px 0 32px;
        background-image: url('/images/clean-norilsk/bg-header-mobile.jpg');
        background-position: center;
    }

    @media (max-width: 370px) {
        background-position: center top;
    }

    &__title,
    &__subtitle {
        line-height: 1;
        background: linear-gradient(180deg, #FFFFFF 0%, #8BBBFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    &__title {
        font-weight: 800;
        font-size: 124px;
        letter-spacing: -0.03em;
        margin-bottom: 54px;

        @include breakpoint(tablet) {
            font-size: 80px;
            margin-bottom: 40px;
        }

        @include breakpoint(v-tablet) {
            font-size: 48px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 24px;
        }

        @media (max-width: 400px) {
            font-size: 40px;
        }
    }
    &__subtitle {
        font-weight: 250;
        font-size: 96px;
        letter-spacing: -0.05em;

        @include breakpoint(tablet) {
            font-size: 62px;
        }

        @include breakpoint(v-tablet) {
            font-size: 36px;
        }

        @media (max-width: 400px) {
            font-size: 32px;
        }
    }
    &__description {
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2;
        letter-spacing: -0.005em;
        color: #1A1B25;
        margin-bottom: 24px;
        max-width: 451px;

        @include breakpoint(tablet) {
            font-size: 23px;
            max-width: 451px;
        }

        @include breakpoint(v-tablet) {
            font-size: 20px;
            color: #FFFFFF;
        }

        @media (max-width: 400px) {
            margin-bottom: 216px;
        }
    }
    .z-button {
        font-weight: 600;

        @include breakpoint(v-tablet) {
            font-size: 18px;
            padding-top: 11px;
            padding-bottom: 11px;
        }
    }
}
</style>
